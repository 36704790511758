import { useState } from 'react'
import { Popover2 } from '@blueprintjs/popover2'
import { TopBarUserMenuContainer } from 'src/components/Common/styled/popup'
import { AnchorButton } from 'src/components/Common/styled/buttons'
import { Button } from '@verbit-ai/verbit-ui-library'
import { SettingsIcon, CaretDownIcon } from '@verbit-ai/icons-library'
import styled from 'styled-components/macro'
import { useTheme } from 'styled-components/macro'
import { UserSettings } from 'src/components/UserSettings/UserSettings'
import { ANALYTICS_CONSTS, useAnalytics } from 'src/analytics'
// import ANALYTICS_CONSTS from 'src/analytics/constants'

export function TopBarUserMenu(props: { workerName: string }) {
    const [isOpen, setIsOpen] = useState(false)
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
    const theme = useTheme()
    const analytics = useAnalytics()

    const Caret = styled(CaretDownIcon)<{ $isOpen: boolean }>`
        display: inline-block;
        transition: transform 0.3s ease;
        transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    `
    const handleSettings = () => {
        setIsSettingsModalOpen(true)
        analytics?.sendEventTrigger(
            ANALYTICS_CONSTS.Features.TOP_BAR,
            ANALYTICS_CONSTS.Session.USER_SETTINGS_CLICKED,
        )
    }
    const handleCloseSettingsModal = () => setIsSettingsModalOpen(false)

    return (
        <Popover2
            className="no-shadow"
            isOpen={isOpen}
            modifiers={{ arrow: { enabled: false }, offset: { options: { offset: [0, 9] } } }}
            onClose={() => setIsOpen(false)}
            canEscapeKeyClose
            placement="bottom-end"
            content={
                <TopBarUserMenuContainer>
                    <Button
                        variant="link"
                        leftIcon={<SettingsIcon />}
                        onClick={handleSettings}
                        style={{
                            left: -15,
                            textDecoration: 'none',
                            color: `${theme.palette.black}`,
                        }}
                    >
                        Settings
                    </Button>
                </TopBarUserMenuContainer>
            }
        >
            <>
                <AnchorButton onClick={() => setIsOpen(!isOpen)}>
                    {props.workerName}
                    <Caret $isOpen={isOpen} />
                </AnchorButton>
                <UserSettings isOpen={isSettingsModalOpen} onClose={handleCloseSettingsModal} />
            </>
        </Popover2>
    )
}
