import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Position, Toaster } from '@blueprintjs/core'
import { ItemListRenderer, ItemPredicate, ItemRenderer } from '@blueprintjs/select'
import { Button, OptionType, Select } from '@verbit-ai/verbit-ui-library'
import useWillUnmount from 'beautiful-react-hooks/useWillUnmount'
import { isEmpty, isEqual, omit, pick } from 'lodash/fp'
import * as S from './styled'
import { APIError } from 'src/network'
import { Speaker } from 'src/models'
import { usePrevious } from 'src/hooks/usePrevious'
import { getDefaultVoiceSampleId, isVoiceSamplePlaying } from 'src/utils/speaker'
import { useGlossary } from 'src/state/GlossaryProvider'
import {
    DEFAULT_SPEAKER,
    PendingSpeaker,
    useSpeakers,
    useSpeakersById,
} from 'src/state/SpeakersProvider'
import {
    useSpeakerVoiceSamplePlayer,
    useToggleSpeakerVoiceSample,
} from 'src/state/SpeakerVoiceSamplePlayerProvider'
import { useRoleHotkeys } from 'src/hooks/settings/useRoleHotkeys'
import { PersonIcon } from 'src/components/icons'
import { VoiceSampleField } from './VoiceSampleField'
import { Hotkey } from 'src/models/hotkeys'
import { useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'

const filterStringValue: ItemPredicate<string> = (query, value, i, exactMatch) => {
    const normalizedValue = value.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    return exactMatch
        ? normalizedValue === normalizedQuery
        : normalizedValue.indexOf(normalizedQuery) >= 0
}

const nameListRenderer: ItemListRenderer<string> = ({
    itemsParentRef,
    renderItem,
    filteredItems,
}) => (
    <S.StyledMenu ulRef={itemsParentRef}>
        <S.StyledMenuHeader text="People from Glossary" />
        {filteredItems.length ? (
            filteredItems.map(renderItem)
        ) : (
            <S.StyledMenuItem text="No Suggestions" disabled />
        )}
    </S.StyledMenu>
)

const nameRenderer: ItemRenderer<string> = (name, { handleClick, modifiers }) => (
    <S.StyledMenuItem
        key={name}
        text={name}
        onClick={handleClick}
        active={modifiers.active}
        icon={<PersonIcon />}
    />
)

type FormErrors<T> = {
    [Key in keyof T]?: string
}

export const SpeakerForm = () => {
    const { currentPlayingVoiceSample, ...speakerVoiceSamplePlayer } = useSpeakerVoiceSamplePlayer()
    const toggleSpeakerVoiceSample = useToggleSpeakerVoiceSample()
    const {
        speakers,
        addSpeaker,
        updateSpeaker,
        speakerForm,
        setSpeakerFormValue,
        setIsSpeakerFormVisible,
    } = useSpeakers()
    const speakersById = useSpeakersById()
    const { glossary } = useGlossary()
    const analytics = useAnalytics()

    const containerRef = useRef<HTMLDivElement>(null)
    const toasterRef = useRef<Toaster>(null)

    const [errors, setErrors] = useState<FormErrors<Speaker>>({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitNetworkError, setSubmitNetworkError] = useState('')
    const [isClosing, setIsClosing] = useState(false)

    const { roleHotkeys } = useRoleHotkeys()

    // Speaker details to update
    const { value: formValue } = speakerForm

    // check what roles are being used to disable them
    const usedRoleIds = speakers
        .filter((speaker) => speaker.id !== formValue.id)
        .map((speaker) => speaker.hotkey?.id)

    const options =
        roleHotkeys &&
        roleHotkeys.map((role) => ({
            value: role.id!,
            label: role.name,
            icon: <S.RoleColorLabel color={role.color || 'white'} />,
            isDefaultRole: true,
            isDisabled: usedRoleIds.includes(role.id),
        }))

    const defaultSampleId = useMemo(
        () => getDefaultVoiceSampleId(formValue.samples),
        [formValue.samples],
    )

    const sourceSpeaker = useMemo(
        () => (formValue.id ? speakersById[formValue.id] : null),
        [speakersById, formValue.id],
    )
    const nameSuggestions = useMemo(
        () => glossary.terms.filter((term) => term.category === 'person').map(({ text }) => text),
        [glossary],
    )

    const prevSourceSpeaker = usePrevious(sourceSpeaker)

    const close = useCallback(() => setIsClosing(true), [])
    const onContainerAnimationEnd = useCallback(() => {
        if (isClosing) {
            setIsSpeakerFormVisible(false)
            setSpeakerFormValue(DEFAULT_SPEAKER)
            setErrors({})
            setSubmitNetworkError('')
            setIsClosing(false)
        }
    }, [isClosing, setIsSpeakerFormVisible, setSpeakerFormValue])

    const onFieldChange = useCallback(
        function <T extends keyof Speaker>(fieldName: T) {
            return (fieldValue: Speaker[T]) => {
                setErrors((errors) => ({ ...errors, [fieldName]: '' }))
                setSpeakerFormValue((speaker) => ({ ...speaker, [fieldName]: fieldValue }))
            }
        },
        [setSpeakerFormValue],
    )

    const onNameChange = (newName: string) => {
        onFieldChange('name')(newName)

        const foundSpeaker = speakers.find(
            (existingSpeaker) =>
                existingSpeaker.id !== formValue.id && existingSpeaker.name === newName,
        )
        if (foundSpeaker) {
            setErrors((errors) => ({ ...errors, name: 'Name already exists' }))
        }
    }

    const updateRole = (role: string) => {
        onFieldChange('role')(role)
        // Role and hotkey cannot be both set, so we'll know which one of them to choose
        onFieldChange('hotkey')(undefined)
    }

    const updateHotkey = (hotkey?: Hotkey) => {
        onFieldChange('role')('')
        // Role and hotkey cannot be both set, so we'll know which one of them to choose
        onFieldChange('hotkey')(hotkey)
    }

    // When user select role from list
    const handleSpeakerRoleOnChange = (option: OptionType) => {
        if (!option) {
            updateHotkey(undefined)
            return
        }
        const foundHotkey = roleHotkeys?.find((hotkey) => hotkey.id === option.value)
        foundHotkey ? updateHotkey(foundHotkey) : updateRole(option.value.toString())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onVoiceSamplesChange = useCallback(onFieldChange('samples'), [onFieldChange])

    const onSubmit = async () => {
        setSubmitNetworkError('')

        const nextFormErrors: FormErrors<Speaker> = {}
        const speakerFormValue = { ...formValue }

        if (!speakerFormValue.name.trim()) {
            speakerFormValue.name = speakerFormValue.hotkey?.name || speakerFormValue.role
        }

        if (Object.values(nextFormErrors).some(Boolean)) {
            setErrors(nextFormErrors)
            return
        }

        setIsSubmitting(true)
        try {
            await (speakerFormValue.id
                ? updateSpeaker({
                      ...speakerFormValue,
                      id: speakerFormValue.id,
                  })
                : addSpeaker(speakerFormValue))
            setIsSubmitting(false)
            close()
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.SPEAKERS,
                ANALYTICS_CONSTS.Speakers.SPEAKER_CREATED(speakerFormValue.name),
            )
        } catch (e) {
            setIsSubmitting(false)
            setSubmitNetworkError(
                e instanceof APIError ? e.message : 'Submit network error, please try again',
            )
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.SPEAKERS,
                ANALYTICS_CONSTS.Speakers.SPEAKER_CREATE_ERROR,
            )
        }
    }

    const onToggleVoiceSample = useCallback(
        (sampleId: string, isPlaying: boolean, e: React.MouseEvent) => {
            if (formValue.id) {
                toggleSpeakerVoiceSample(formValue.id, sampleId, isPlaying, e)
                analytics?.sendEventTrigger(
                    ANALYTICS_CONSTS.Features.SPEAKERS,
                    ANALYTICS_CONSTS.Speakers.SPEAKER_VOICE_SAMPLE_TOGGLED,
                )
            }
        },
        [toggleSpeakerVoiceSample, formValue.id, analytics],
    )
    const onSetVoiceSampleAsDefault = useCallback(
        (sampleId: string) => {
            if (defaultSampleId === null) {
                return
            }

            const updatedSamples = {
                ...formValue.samples,
                [sampleId]: { ...formValue.samples[sampleId], isDefault: true },
                [defaultSampleId]: { ...formValue.samples[defaultSampleId], isDefault: false },
            }

            onVoiceSamplesChange(updatedSamples)
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.SPEAKERS,
                ANALYTICS_CONSTS.Speakers.SPEAKER_VOICE_SAMPLE_SET_DEFAULT,
            )
        },
        [formValue.samples, defaultSampleId, onVoiceSamplesChange, analytics],
    )
    const onDeleteVoiceSample = useCallback(
        (sampleId: string) => {
            speakerVoiceSamplePlayer.pause()
            onVoiceSamplesChange(omit([sampleId], formValue.samples))
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.SPEAKERS,
                ANALYTICS_CONSTS.Speakers.SPEAKER_VOICE_SAMPLE_DELETED,
            )
        },
        [formValue.samples, onVoiceSamplesChange, speakerVoiceSamplePlayer, analytics],
    )

    const prevIsFormVisible = usePrevious(speakerForm.isVisible)
    useEffect(() => {
        // if we open the form while another speaker's voice sample is playing
        if (
            !prevIsFormVisible &&
            speakerForm.isVisible &&
            currentPlayingVoiceSample?.speakerId !== formValue.id
        ) {
            speakerVoiceSamplePlayer.pause()
        }
        //  if we closed the form while a non-default voice sample of the speaker was still playing
        else if (
            prevIsFormVisible &&
            !speakerForm.isVisible &&
            prevSourceSpeaker?.samples &&
            currentPlayingVoiceSample?.sampleId !==
                getDefaultVoiceSampleId(prevSourceSpeaker?.samples)
        ) {
            speakerVoiceSamplePlayer.pause()
        }
    }, [
        formValue.id,
        prevSourceSpeaker,
        prevIsFormVisible,
        speakerForm.isVisible,
        speakerVoiceSamplePlayer,
        currentPlayingVoiceSample,
    ])

    useEffect(() => {
        if (speakerForm.isVisible) {
            setTimeout(() => speakerForm.nameInputRef.current?.focus({ preventScroll: true }))
        }
    }, [speakerForm.isVisible, speakerForm.nameInputRef])

    // if the source speaker got updated, reload the form and notify the user about it
    useEffect(() => {
        const pickFields = pick<PendingSpeaker, keyof PendingSpeaker>(['name', 'role', 'samples'])

        if (
            sourceSpeaker &&
            prevSourceSpeaker &&
            !isEqual(pickFields(sourceSpeaker), pickFields(prevSourceSpeaker)) &&
            !isEqual(pickFields(sourceSpeaker), pickFields(formValue))
        ) {
            if (
                document.activeElement instanceof HTMLElement &&
                containerRef.current?.contains(document.activeElement)
            ) {
                document.activeElement.blur()
            }

            toasterRef.current?.show({
                intent: 'none',
                message: 'Speaker was updated by one of your teammates',
            })
            setSpeakerFormValue(sourceSpeaker)
        }
    }, [sourceSpeaker, prevSourceSpeaker, formValue, setSpeakerFormValue])

    useWillUnmount(() => {
        setSpeakerFormValue(DEFAULT_SPEAKER)
        setIsSpeakerFormVisible(false)
    })

    if (!speakerForm.isVisible) {
        return null
    }

    return (
        <S.Container
            ref={containerRef}
            isClosing={isClosing}
            onAnimationEnd={onContainerAnimationEnd}
        >
            <S.FormFieldsContainer>
                <S.FormHeader>{formValue.id ? 'Edit Speaker' : 'Add Speaker'}</S.FormHeader>

                {isEmpty(formValue.samples) && !formValue.id && (
                    <S.LabelFormField label="">
                        Voice sample will be added once the speaker is assigned to audio segments
                    </S.LabelFormField>
                )}

                <S.StyledFormField
                    data-testid="speaker-name-field"
                    label="Name"
                    error={errors.name}
                >
                    <S.StyledAutoSuggestInput
                        items={nameSuggestions}
                        value={formValue.name}
                        onChange={onNameChange}
                        itemPredicate={filterStringValue}
                        itemListRenderer={nameListRenderer}
                        itemRenderer={nameRenderer}
                        placeholder="Type a Name"
                        inputProps={{ inputRef: speakerForm.nameInputRef }}
                        openOnKeyDown
                    />
                </S.StyledFormField>

                <S.StyledFormField
                    data-testid="speaker-role-field"
                    label="Role (optional)"
                    error={errors.role}
                >
                    <Select
                        options={options}
                        isCreatable
                        isSearchable
                        isClearable
                        isCreatableNoButton
                        noOptionsMessage={() => null}
                        placeholder="Select or type a role"
                        onChange={handleSpeakerRoleOnChange as unknown as (value: any) => void}
                        value={{
                            label: formValue.role || formValue.hotkey?.name || '',
                            value: formValue.role || formValue.hotkey?.id || '',
                        }}
                        components={{
                            ClearIndicator: () => null,
                        }}
                    />
                </S.StyledFormField>

                {!isEmpty(formValue.samples) && (
                    <S.VoiceSamplesFormField label="Voice Samples">
                        {!isEmpty(formValue.samples) && (
                            <S.VoiceSampleList>
                                {Object.values(formValue.samples).map((sample) => {
                                    const isPlaying =
                                        !!formValue.id &&
                                        isVoiceSamplePlaying(
                                            formValue.id,
                                            sample.id,
                                            currentPlayingVoiceSample,
                                        )

                                    return (
                                        <VoiceSampleField
                                            key={sample.id}
                                            sample={sample}
                                            isDefault={defaultSampleId === sample.id}
                                            isPlaying={isPlaying}
                                            isBuffering={
                                                isPlaying && speakerVoiceSamplePlayer.isBuffering
                                            }
                                            onToggleVoiceSample={onToggleVoiceSample}
                                            onSetAsDefault={onSetVoiceSampleAsDefault}
                                            onDelete={onDeleteVoiceSample}
                                        />
                                    )
                                })}
                            </S.VoiceSampleList>
                        )}
                    </S.VoiceSamplesFormField>
                )}

                <S.FormFooter>
                    {speakers.length > 0 && (
                        <Button variant="secondary" onClick={close} isDisabled={!speakers.length}>
                            Back
                        </Button>
                    )}

                    <Button
                        variant="primary"
                        onClick={onSubmit}
                        isDisabled={isSubmitting || Object.values(errors).some(Boolean)}
                    >
                        {isSubmitting && <S.SubmitSpinner />}
                        {formValue.id ? 'Save Changes' : 'Add Speaker'}
                    </Button>
                </S.FormFooter>
            </S.FormFieldsContainer>

            <S.SubmitNetworkError>{submitNetworkError}</S.SubmitNetworkError>

            <S.StyledToaster ref={toasterRef} position={Position.TOP} usePortal={false} />
        </S.Container>
    )
}
