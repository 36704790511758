import { Editor } from 'slate'
import { Block } from 'src/components/Editor/plugins/withTranscript'
import { useFeatureFlag } from 'src/state/FeatureFlagProvider'
import { EventsList } from 'src/models/events'
import { getBlockFormattedMediaTimeCode } from 'src/utils/timecode'
import { ANALYTICS_CONSTS, useAnalytics } from 'src/analytics'

export enum EventsActionType {
    UPSERT = 'upsert',
    DELETE = 'delete',
    SPLIT = 'split',
    MERGE = 'merge',
}

export type EventAction =
    | { type: EventsActionType.UPSERT; event: EventsList }
    | { type: EventsActionType.DELETE; blockIndex: number }
    | { type: EventsActionType.SPLIT; blockIndex: number }
    | { type: EventsActionType.MERGE; blockIndex: number }

type WithEventsOptions = {
    onChangeEvent(action: EventAction): void
}

export function withEvents(options: WithEventsOptions) {
    const { onChangeEvent } = options

    return (editor: Editor) => {
        const analytics = useAnalytics()
        const eventsMarkingFlag = useFeatureFlag('add_events_marking')
        const eventsLibTabEnabled = useFeatureFlag('events_list_tab')
        const { onChange } = editor

        editor.onChange = () => {
            onChange()

            if (!eventsMarkingFlag && !eventsLibTabEnabled) return
            for (const op of editor.operations) {
                switch (op.type) {
                    case 'remove_node': {
                        const { node, path } = op
                        const [blockIndex] = path

                        if (Block.isBlock(node)) {
                            if (node.section) {
                                onChangeEvent({
                                    type: EventsActionType.DELETE,
                                    blockIndex,
                                })

                                analytics?.sendEventTrigger(
                                    ANALYTICS_CONSTS.Features.EDITOR,
                                    ANALYTICS_CONSTS.Operation.PARAGRAPH_DELETE,
                                )
                            } else {
                                onChangeEvent({
                                    type: EventsActionType.MERGE,
                                    blockIndex,
                                })

                                analytics?.sendEventTrigger(
                                    ANALYTICS_CONSTS.Features.EDITOR,
                                    ANALYTICS_CONSTS.Operation.PARAGRAPH_MERGE,
                                )
                            }
                        }

                        break
                    }

                    case 'set_node': {
                        const { path, newProperties } = op
                        const [blockIndex] = path
                        const mediaTimeCode = getBlockFormattedMediaTimeCode(blockIndex, editor)

                        if (
                            (newProperties as any).section ||
                            (newProperties as any).section === ''
                        ) {
                            onChangeEvent({
                                type: EventsActionType.UPSERT,
                                event: {
                                    index: blockIndex,
                                    mediaTimeCode: mediaTimeCode!,
                                    section: (newProperties as any).section,
                                },
                            })
                            analytics?.sendEventTrigger(
                                ANALYTICS_CONSTS.Features.EDITOR,
                                ANALYTICS_CONSTS.Operation.PARAGRAPH_EDITED,
                            )
                        }

                        break
                    }

                    case 'split_node': {
                        const { properties, path } = op
                        const [blockIndex] = path

                        if (Block.isBlock(properties)) {
                            onChangeEvent({
                                type: EventsActionType.SPLIT,
                                blockIndex,
                            })
                            analytics?.sendEventTrigger(
                                ANALYTICS_CONSTS.Features.EDITOR,
                                ANALYTICS_CONSTS.Operation.PARAGRAPH_BREAK,
                            )
                            break
                        }
                    }
                }
            }
        }

        return editor
    }
}
