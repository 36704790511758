import { AppStateSchema } from 'src/state/state-machines/AppMachine/AppMachine'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useIsTaskMachineActive } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { TaskMachineLogger } from 'src/state/TaskMachineLogger'
import { EnrollLoader, EnrollWorker } from 'src/components/EnrollWorker/EnrollWorker'
import { Session } from 'src/components/Session/Session'
import { LiveSession } from 'src/components/Session/live'
import { Stats, StatsLoader } from 'src/components/Exit/Stats'
import { RejectedAuth } from 'src/components/EnrollWorker/RejectedAuth'
import { DuplicateSessionScreen } from 'src/components/DuplicateSessionScreen/DuplicateSessionScreen'
import { AppEventsProvider } from 'src/state/AppEventsProvider'

import { AppErrorStateHandler } from './AppErrorStateHandler'
import { UnsupportedBrowser } from '../UnsupportedBrowser'

export function App() {
    const [state] = useAppMachine(['state'])
    const isTaskMachineActive = useIsTaskMachineActive()

    switch (state.value as keyof AppStateSchema['states']) {
        case 'backdoor-join-screen':
            return <EnrollWorker />
        case 'join-attempt':
            return <EnrollLoader />
        case 'rejected-join-attempt':
            return <RejectedAuth />
        case 'working-live':
            return (
                <AppEventsProvider>
                    <LiveSession />
                </AppEventsProvider>
            )
        case 'working':
            return (
                <>
                    <DuplicateSessionScreen />
                    {isTaskMachineActive && <Session />}
                    <TaskMachineLogger />
                </>
            )
        case 'processing':
            return <></>
        case 'loading-stats':
            return <StatsLoader />
        case 'stats':
            return <Stats />
        case 'error':
            return <AppErrorStateHandler />
        case 'unsupported-browser':
            return <UnsupportedBrowser />
        default:
            return <>{JSON.stringify(state)}</>
    }
}
