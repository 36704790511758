import { MethodType } from '../types/types'

type OptionsType = {
    method?: string
    headers?: Record<string, string>
    body?: {}
    queryParams?: Record<string, string | number>
}

type FetchAPIProps = {
    methodType: MethodType
    apiUrl: string
    bearerName: string
    authtoken: string
    options?: OptionsType
}

export const fetchAPI = async ({
    methodType,
    apiUrl,
    bearerName,
    authtoken,
    options = {},
}: FetchAPIProps): Promise<Response> => {
    const { method, headers, body, queryParams, ...extras } = options

    const queryString = queryParams
        ? `?${new URLSearchParams(
              Object.entries(queryParams).map(([key, value]) => [key, String(value)]),
          ).toString()}`
        : ''
    const fullUrl = `${apiUrl}${queryString}`

    const fetchOptions: RequestInit = {
        method: `${methodType}`,
        credentials: 'include', // Include cookies in the request
        headers: { 'Content-Type': 'application/json', ...headers },
        redirect: 'follow' as RequestRedirect,
        ...extras,
    }

    if (body) {
        fetchOptions.body = JSON.stringify(body)
    }
    if (authtoken) {
        if (!fetchOptions.headers) {
            fetchOptions.headers = {}
        }
        ;(fetchOptions.headers as Record<string, string>)['Authorization'] = `${
            bearerName === '' ? 'ApiToken ' : bearerName
        }${authtoken}`
    }

    return await fetch(`${fullUrl}`, fetchOptions).then((res) => {
        return new Promise((resolve, reject) => {
            const contentType = res.headers.get('content-type')
            if (!res.ok) {
                const error = res && res.statusText
                reject(error)
            }
            if (res.status === 401) throw new Error('Request unauthorized!')
            if (contentType === null) return Promise.resolve(null)
            else if (contentType.startsWith('application/json;')) resolve(res.json())
            else if (contentType.startsWith('text/plain;'))
                res.text().then((text) => resolve(new Response(text)))
            else throw new Error(`Unsupported response content-type: ${contentType}`)
        })
    })
}
