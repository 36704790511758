/// <reference types="styled-components/cssprop" />

import ReactDOM from 'react-dom/client'
import { QueryClientProvider, QueryClient } from 'react-query'
import LogRocket from 'logrocket'
import '@blueprintjs/core/lib/css/blueprint.css'
import { FocusStyleManager } from '@blueprintjs/core'
import { ThemeProvider } from 'styled-components/macro'
import { VerbitThemeProvider, theme as verbitTheme } from '@verbit-ai/verbit-ui-library'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { AppMachineProvider } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { TaskMachineProvider } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { TaskValidationMachineProvider } from 'src/state/state-machines/TaskValidationMachine/TaskValidationMachineProvider'
import { initRollbar } from 'src/utils/rollbar'
import {
    getEnv,
    setCloudFrontCookie,
    setVerbitUniversalTokenCookie,
    shouldShowIconListPage,
} from 'src/utils/env'
import { theme } from 'src/components/styled'
import { Router } from 'src/components/Router'
import { IconsPreview } from 'src/components/IconsPreview/IconsPreview'
import { ErrorBoundary } from 'src/components/Common/ErrorBoundary'
import { ErrorFallbackScreen } from 'src/components/Common/ErrorFallbackScreen'
import { AppHelmet } from 'src/components/AppHelmet'

import './index.css'
import { LiveMachineProvider } from './state/state-machines/LiveMachine/LiveMachineProvider'
import { renderMockControls } from './network/mocks'

const env = getEnv()

initRollbar(env, process.env.REACT_APP_ROLLBAR_TOKEN, process.env.REACT_APP_GIT_COMMIT)

FocusStyleManager.onlyShowFocusOnTabs()

if (env !== 'dev') {
    LogRocket.init('ofbugl/transcription-viewer', {
        release: process.env.REACT_APP_GIT_COMMIT,
    })

    const apiEndpoint = new URLSearchParams(window.location.search).get('api_endpoint')
    const currentPlatformJobId = apiEndpoint?.split('job/')[1]
    const recentPlatformJobId = window.localStorage.getItem('recentPlatformJobId')

    if (currentPlatformJobId) {
        window.localStorage.setItem('recentPlatformJobId', currentPlatformJobId)

        if (recentPlatformJobId && currentPlatformJobId !== recentPlatformJobId) {
            LogRocket.startNewSession()
        }
    }
}

if (env === 'dev') {
    setCloudFrontCookie()
    setVerbitUniversalTokenCookie()
}

const queryClient = new QueryClient()
const container = document.getElementById('root')
const root = ReactDOM.createRoot(container!)

if (env === 'dev' && shouldShowIconListPage()) {
    root.render(<IconsPreview />)
} else {
    root.render(
        <QueryClientProvider client={queryClient}>
            <VerbitThemeProvider theme={verbitTheme}>
                <ThemeProvider theme={theme}>
                    <ErrorBoundary fallback={ErrorFallbackScreen}>
                        <AppMachineProvider>
                            <TaskMachineProvider>
                                <LiveMachineProvider>
                                    <TaskValidationMachineProvider>
                                        <DndProvider backend={HTML5Backend}>
                                            <>
                                                <AppHelmet />
                                                <Router />
                                                {renderMockControls()}
                                            </>
                                        </DndProvider>
                                    </TaskValidationMachineProvider>
                                </LiveMachineProvider>
                            </TaskMachineProvider>
                        </AppMachineProvider>
                    </ErrorBoundary>
                </ThemeProvider>
            </VerbitThemeProvider>
        </QueryClientProvider>,
    )
}
