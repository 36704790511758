import { NameInput } from './NameInput'
import HotkeyInput from './HotkeyInput'
import { ColorSelect } from 'src/components/Select/ColorSelect'
import { IconButton, OptionType } from '@verbit-ai/verbit-ui-library'
import { RemoveIcon, ReplayIcon } from '@verbit-ai/icons-library'
import { StyledInputStack } from './HotkeysTab'
import styled from 'styled-components/macro'
import { Flex, Stack } from '@chakra-ui/react'
import { useRoleHotkeys } from 'src/hooks/settings/useRoleHotkeys'
import { Hotkey, hotkeyColors } from 'src/models/hotkeys'
import { DeleteHotkeyConfirmation } from './DeleteHotkeyConfirmation'
import { useState } from 'react'

const StyledRemoveButton = styled(IconButton)`
    width: 40px;
    margin-left: 16px;
`

const StyledSelectStack = styled(Stack)`
    width: 192px;
    margin-left: 16px;
`

const StyledReplay = styled(ReplayIcon)`
    margin: 12px 8px;
`
const StyledFlex = styled(Flex)`
    height: 65px;
`

type HotkeyItemProps = {
    hotkey: Hotkey
}
export const HotkeyItem = (props: HotkeyItemProps) => {
    const [hotkey, setHotkey] = useState(props.hotkey)
    const [errors, setErrors] = useState({ name: false, hotkey: false })
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
    const { roleHotkeys, focusedRole, updateRoleHotkeys, deleteRoleHotkeys } = useRoleHotkeys()

    const getAvailableColors = (currentColor?: string) => {
        const selectedColors = roleHotkeys?.map((item) => item.color)
        return hotkeyColors.filter((hotkeyColor) => {
            if (hotkeyColor.value === currentColor) {
                return true
            }
            const isColorTaken = selectedColors?.find(
                (selectedColor) => selectedColor === hotkeyColor.value,
            )
            return !isColorTaken
        })
    }

    const handleDelete = async () => {
        if (!!hotkey.id) {
            await deleteRoleHotkeys(hotkey.id)
        }
    }

    const handleUpdate = (newHotkey: Hotkey) => {
        setHotkey(newHotkey)

        const hasAnyError = Object.values(errors).some((error) => error)
        if (!hasAnyError && newHotkey.value && newHotkey.name) {
            updateRoleHotkeys(newHotkey)
        }
    }

    const handleErrorChange = (fieldId: string, hasError: boolean) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldId]: hasError,
        }))
    }

    return (
        <StyledFlex>
            <StyledInputStack>
                <NameInput
                    onBlur={(value) =>
                        value !== hotkey.name && handleUpdate({ ...hotkey, name: value })
                    }
                    value={hotkey.name}
                    autoFocus={focusedRole === hotkey.name}
                    hotkeyId={hotkey.id}
                    onErrorChange={handleErrorChange}
                />
            </StyledInputStack>
            <StyledReplay />
            <StyledInputStack>
                <HotkeyInput
                    initialValue={hotkey.value}
                    hotkeyId={hotkey.id}
                    onBlur={(value) =>
                        value !== hotkey.value && handleUpdate({ ...hotkey, value: value })
                    }
                    onErrorChange={handleErrorChange}
                />
            </StyledInputStack>
            <StyledSelectStack>
                <ColorSelect
                    getColorOptions={(selectedColor) => getAvailableColors(selectedColor)}
                    selectedColor={hotkey.color}
                    onChange={(option) =>
                        handleUpdate({
                            ...hotkey,
                            color: (option as OptionType)?.value,
                        })
                    }
                />
            </StyledSelectStack>
            <StyledRemoveButton
                variant={'secondarySubtle'}
                onClick={() => setOpenDeleteConfirmation(true)}
            >
                <RemoveIcon />
            </StyledRemoveButton>
            <DeleteHotkeyConfirmation
                isOpen={openDeleteConfirmation}
                onClose={(isConfirmed) =>
                    isConfirmed ? handleDelete() : setOpenDeleteConfirmation(false)
                }
            />
        </StyledFlex>
    )
}
