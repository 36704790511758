import { getEnv, getPlatformUrl } from 'src/utils/env'
import { HTTP_METHODS, API_BEARER_NAME, INVITE_PLATFORM_BASE_ROUTE_URL } from '../constants'
import { fetchAPI } from '../api/fetchAPI'

export const getAPIHostUrl = () => {
    let apiUrl = ''
    const env = getEnv()
    if (env === 'dev') {
        apiUrl = `${process.env.REACT_APP_PLATFORM_API_DEV_URL}${INVITE_PLATFORM_BASE_ROUTE_URL}`
    } else {
        apiUrl = `${getPlatformUrl()}${INVITE_PLATFORM_BASE_ROUTE_URL}`
    }
    return apiUrl
}

// Retrieves all invitations created by the current user
export const getInvitationsList = async (
    apiToken: string,
    orderId: string,
    inviteTemplateID: string,
) => {
    let apiUrl = getAPIHostUrl()
    return await fetchAPI({
        methodType: HTTP_METHODS.GET,
        apiUrl,
        bearerName: `${API_BEARER_NAME}`,
        authtoken: apiToken,
        options: {
            queryParams: {
                order_id: orderId,
                invite_template_id: inviteTemplateID,
            },
        },
    })
}

// Sends invites to the specified email addresses
export const sendInvites = async (
    apiToken: string,
    invites: string[],
    orderId: string,
    inviteTemplateID: string,
) => {
    let apiUrl = getAPIHostUrl()
    return await fetchAPI({
        methodType: HTTP_METHODS.POST,
        apiUrl,
        bearerName: `${API_BEARER_NAME}`,
        authtoken: apiToken,
        options: {
            body: {
                invite: {
                    invites,
                    order_id: orderId,
                    invite_template_id: inviteTemplateID,
                },
            },
        },
    })
}

// Removes an invite by ID
export const removeInvite = async (id: number, apiToken: string) => {
    let apiUrl = getAPIHostUrl() + `/${id}`
    return await fetchAPI({
        methodType: HTTP_METHODS.DELETE,
        apiUrl,
        bearerName: `${API_BEARER_NAME}`,
        authtoken: apiToken,
    })
}
