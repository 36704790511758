import { HotkeyType, hotkeyTypeName } from 'src/models/hotkeys'
import { Input } from '@verbit-ai/verbit-ui-library'
import { ChangeEvent, useEffect, useState } from 'react'
import { useRoleHotkeys } from 'src/hooks/settings/useRoleHotkeys'
import { isNameExists } from 'src/utils/hotkeys'
import { InputError } from './InputError'
import { Stack } from '@chakra-ui/react'

type NameInputProps = {
    type?: HotkeyType
    value?: string
    hotkeyId?: string
    onBlur?: (value: string) => void
    autoFocus?: boolean
    onErrorChange?: (id: string, hasError: boolean) => void
    onChange?: (value: string) => void
}
export const NameInput = ({
    type = 'role',
    value,
    hotkeyId,
    onBlur,
    autoFocus = false,
    onErrorChange,
    onChange,
}: NameInputProps) => {
    const { roleHotkeys } = useRoleHotkeys()
    const [error, setError] = useState('')
    const [inputValue, setInputValue] = useState(value || '')

    useEffect(() => {
        // reset value upon submit
        !value && setInputValue('')
        !!value && validate(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value)
        onChange && onChange(event.target.value)

        validate(event.target.value)
    }

    const validate = (currentName: string) => {
        if (!currentName) {
            setError(`Field cannot be empty`)
            onErrorChange && onErrorChange('name', true)
            return
        }
        if (isNameExists(currentName, hotkeyId, roleHotkeys)) {
            setError(`This ${type} already in use`)
            onErrorChange && onErrorChange('name', true)
            return
        }
        onErrorChange && onErrorChange('name', false)
        setError('')
    }

    const handleBlur = () => {
        onBlur && onBlur(inputValue)
    }

    return (
        <Stack>
            <Input
                type="text"
                name={type}
                size={'lg'}
                placeholder={'Enter ' + hotkeyTypeName[type]}
                value={inputValue}
                onChange={handleChange}
                onBlur={handleBlur}
                isFullWidth
                autoFocus={autoFocus}
                autoComplete={'off'}
            />
            {error && <InputError error={error} />}
        </Stack>
    )
}
