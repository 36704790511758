import { useState } from 'react'
import {
    Modal,
    Button,
    Typography,
    IconButton,
    useToast,
    UseToastOptions,
    Autocomplete,
    OptionType,
} from '@verbit-ai/verbit-ui-library'
import { SelectOnChangeOptionType } from '@verbit-ai/verbit-ui-library/dist/components/Select'
import { RightChevronIcon, LockIcon, CopyIcon } from '@verbit-ai/icons-library'
import { VStack, HStack, Spacer, Text, TextProps } from '@chakra-ui/react'
import { useInvites } from 'src/components/Invites'
import { OmittedBaseTypes } from './types'
import { InviteModalState, EMAIL_REGEX_PATTERN } from '../Invites/constants'
import { sendInvites } from '../Invites/api/apiHelpers'
import { theme } from '@verbit-ai/verbit-ui-library'

interface StyledInvitedViewersProps extends TextProps {
    children: React.ReactNode
}

const StyledInvitedViewers = (props: StyledInvitedViewersProps) => {
    return (
        <Text
            lineHeight="21px"
            fontWeight="400"
            width="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            userSelect="none"
            cursor={props.onClick ? 'pointer' : 'default'}
            {...props}
        >
            {props.children}
        </Text>
    )
}

type ChipStyles = { borderColor: string }
type InvalidChips = Record<string, ChipStyles>

const validateEmail = (value: string) => EMAIL_REGEX_PATTERN.test(value)

const InviteForm = (props: OmittedBaseTypes) => {
    const toast = useToast()
    const {
        setInviteModalState,
        invitedViewers,
        inviteList,
        setInviteList,
        customerUsers,
        apiToken,
        orderId,
        inviteTemplateID,
    } = useInvites()
    const [invalidChips, setInvalidChips] = useState<InvalidChips>({})

    const options = customerUsers.map((user) => ({
        value: user.email,
        label: user.email,
    }))

    const inviteSelectStyles = {
        valueContainer: (base: any) => ({
            ...base,
            display: 'flex',
            flexWrap: 'wrap',
            maxHeight: '70px', // Approx height of 2 rows
            overflowY: 'auto',
        }),
        menuList: (base: any) => ({
            ...base,
            maxHeight: '150px', // Limit the height of the dropdown
            overflowY: 'auto', // Add vertical scrollbar for overflow
        }),
    }

    const autoCompleteBoxProps = {
        width: '100%',
        maxWidth: '85%',
        maxHeight: '70px',
    }

    const handleClickInvite = async () => {
        if (!inviteList.length) {
            toast({
                id: 'no-emails',
                type: 'error',
                title: 'Please add at least one email address.',
                isClosable: true,
            } as UseToastOptions)
            return
        }

        const res = await sendInvites(apiToken, inviteList, orderId, inviteTemplateID)
        if (res && Array.isArray(res)) {
            toast({
                id: 'invitations-sent',
                type: 'info',
                title: 'Invitations sent successfully.',
                isClosable: true,
            } as UseToastOptions)
            setInviteList([])
            setInviteModalState(InviteModalState.LIST)
        }
    }

    const onInvitesValueChange = (options: SelectOnChangeOptionType) => {
        if (!Array.isArray(options)) return

        const optionsValues = (options as OptionType<string>[]).map((option) => option.value)

        const invalidEmails = optionsValues.filter((option) => !validateEmail(option))
        if (invalidEmails.length === 0) {
            if (optionsValues.length === 0) {
                setInvalidChips({})
                setInviteList([])
                return
            }

            setInviteList(optionsValues.join(',').split(','))
            return
        }

        const chips: InvalidChips = {}
        invalidEmails.forEach((email) => {
            chips[email] = { borderColor: theme.colors.red[100] }
        })
        setInvalidChips(chips)
    }

    // copies the Read Only URL to the clipboard
    const handleShareLink = () => {
        if (props.shareLink) {
            navigator.clipboard.writeText(String(props.shareLink))
            toast({
                id: 'link-copied',
                type: 'info',
                title: 'Link copied to clipboard',
                isClosable: true,
            } as UseToastOptions)
        }
    }

    // iterate over the invited viewers and display them in a comma separated list
    // NOTE: this list will only contain the email addresses of the invited viewers after clicking the `INVITE` button
    const displayedItems = invitedViewers.map((item, index) => {
        const isLast = index === invitedViewers.length - 1
        const displayText = `${item?.name || item.email}${!isLast ? ', ' : ''}`
        return displayText
    })
    const displayItemsList = displayedItems.join('')

    return (
        <>
            <Modal.CloseButton />
            <Modal.Header>
                {props.modalTitle || 'Invite to view the deposition live transcript'}
            </Modal.Header>
            <Modal.Body>
                <HStack spacing="5px">
                    <Autocomplete
                        id="invite-select"
                        name="invite-select"
                        ariaLabel="invite-user-by-email"
                        isCreatable={false} // Disable creating new options as this will be for Phase 2
                        onChange={onInvitesValueChange}
                        defaultOptions={options}
                        tagsStyles={invalidChips}
                        boxProps={autoCompleteBoxProps}
                        placeholder="Add emails"
                        value={inviteList.map((email) => ({ value: email, label: email }))}
                        noOptionsMessage={() => 'No matching users found.'}
                        styles={inviteSelectStyles}
                    />
                    <Button onClick={handleClickInvite}>Invite</Button>
                </HStack>

                <HStack spacing="5px" mt={3} mb={3}>
                    <LockIcon />
                    <span>
                        {props.subTitle || 'Only invited people can view the live transcript.'}
                    </span>
                </HStack>
                <hr />

                <VStack spacing="5px" mt={3} align={'flex-start'}>
                    <Typography variant="h6">
                        {props.inviteListLabel || 'Invited viewers'}
                        {` (${displayedItems.length})`}
                    </Typography>

                    {!displayedItems.length && (
                        <Typography italic size="sm" color={theme.colors.gray[900]}>
                            No viewers invited yet
                        </Typography>
                    )}

                    <HStack spacing="5px" width="100%">
                        <StyledInvitedViewers
                            color={theme.colors.blue[1100]}
                            onClick={() => setInviteModalState(InviteModalState.LIST)}
                        >
                            {displayItemsList}
                        </StyledInvitedViewers>
                        <Spacer />
                        {displayedItems.length && (
                            <IconButton
                                variant="link"
                                icon={<RightChevronIcon />}
                                aria-label="open-invited-viewers-icon"
                                onClick={() => setInviteModalState(InviteModalState.LIST)}
                                style={{ padding: 0, marginTop: '-10px' }}
                            />
                        )}
                    </HStack>
                </VStack>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" leftIcon={<CopyIcon />} onClick={handleShareLink}>
                    Copy link
                </Button>
            </Modal.Footer>
        </>
    )
}

export default InviteForm
