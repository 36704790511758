import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { theme } from 'src/components/styled'
import { ifProp } from 'styled-tools'
import { Spacer, Box } from '@chakra-ui/react'
import { Toolbar, IconButton, Button, ButtonGroup } from '@verbit-ai/verbit-ui-library'
import { DownloadIcon, UploadIcon } from '@verbit-ai/icons-library'
import { EndSessionModal } from './EndSessionModal'
import { InviteButton } from 'src/components/Invites'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import {
    useIsLiveMachineActive,
    useLiveMachine,
} from 'src/state/state-machines/LiveMachine/LiveMachineProvider'
import { useFeatureFlag } from 'src/state/FeatureFlagProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { Tooltip } from '@verbit-ai/verbit-ui-library'
import { useToast, UseToastOptions } from '@verbit-ai/verbit-ui-library'
import MediaSource from './MediaSource'
import { getAuthToken } from 'src/utils/env'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'
import { useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'

const StyledToolbar = styled(Toolbar)<{ $isToolbarVisible: boolean }>`
    position: relative;
    display: ${ifProp('$isToolbarVisible', 'flex', 'none !important')};
    background-color: transparent;
    width: 100%;
    padding: 10px;
    margin-right: 22px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &.visible {
        opacity: 1;
    }

    &.hidden {
        opacity: 0;
    }
`

const StyledIconButton = styled(IconButton)`
    &:active,
    &:focus {
        border: none;
    }
`

export const TaskToolbar = () => {
    const [
        {
            context: { httpClient },
        },
    ] = useAppMachine(['httpClient'])
    const isLiveMachineActive = useIsLiveMachineActive()
    const [
        {
            context: { controls, redirect_urls, mediaSource },
        },
    ] = useLiveMachine(['controls', 'redirect_urls', 'mediaSource'])
    const allowInvites = useFeatureFlag('allow_invites')
    const analytics = useAnalytics()
    const { sessionStatus } = useSessionStatus(['sessionStatus.orderId'])
    const READ_ONLY_URL = redirect_urls?.read_only_url
    const ORDERING_URL = redirect_urls?.ordering_url
    const isReadOnly = controls?.toolbar?.link_to_read_only?.visible
    const isEndSessionVisible = controls?.toolbar?.finish_layer?.visible
    const isDownloadTranscript = controls?.toolbar?.download_transcript?.visible
    const isToolbarVisible = controls?.toolbar?.visible
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const [isVisible, setIsVisible] = useState(isToolbarVisible)
    const [isEndSession, setIsEndSession] = useState(false)
    const handleMouseEnter = () => isToolbarVisible && setIsVisible(true)
    const toastFactory = useToast()
    const [isVCRecording, setIsVCRecording] = useState(false) // state for verbit connect - is recording or not
    const [platformAuthToken, setPlatformAuthToken] = useState('')

    const args = {
        type: 'info',
        title: 'Link copied to clipboard',
    } as UseToastOptions

    // set the platform auth token for the InviteButton component
    useEffect(() => {
        if (!allowInvites && !sessionStatus) return

        // NOTE: After deployment to staging we have identified that that auth_token passed in the URL
        // is not working as expected. So we are using the staging_id_token cookie to get the auth_token
        // for invoking the Platform API. Cookies are being sent via request headers.
        // TODO: update component props to remove apiToken
        const authToken = getAuthToken()
        setPlatformAuthToken(authToken || '')

        return () => setPlatformAuthToken('')
    }, [allowInvites, sessionStatus])

    // copies the Read Only URL to the clipboard
    const handleShareURL = () => {
        if (READ_ONLY_URL) {
            navigator.clipboard.writeText(String(READ_ONLY_URL))
            toastFactory(args)
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.EDITOR,
                ANALYTICS_CONSTS.Session.SHARE_LINK_CLICKED,
            )
        }
    }

    const handleStopEditing = async () => {
        setIsEndSession(!isEndSession)
        analytics?.sendEventTrigger(
            ANALYTICS_CONSTS.Features.EDITOR,
            ANALYTICS_CONSTS.Session.STOP_EDITING_CLICKED,
        )
    }

    // redirects the user to Ordering Page
    const handleEndSession = async (value: boolean) => {
        setIsEndSession(false)

        if (value && ORDERING_URL) {
            await httpClient?.transcriptFinalize()
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.EDITOR,
                ANALYTICS_CONSTS.Session.END_SESSION_CLICKED,
            )
            window.open(String(ORDERING_URL), '_self')
        }
    }

    const disableStopButton =
        mediaSource?.toLowerCase() !== 'verbit_connect' ? false : isVCRecording

    return (
        <StyledToolbar
            className={`toolbar ${isLiveMachineActive && isVisible ? 'visible' : 'hidden'}`}
            onMouseEnter={handleMouseEnter}
            $isToolbarVisible={!!isToolbarVisible}
        >
            <Spacer />
            <ButtonGroup gap="2">
                {/* INVITES FEATURE - all props below are required for the InviteButton component */}
                {!isRealTimeReadOnly && allowInvites && sessionStatus && (
                    <InviteButton
                        apiToken={platformAuthToken}
                        orderId={sessionStatus?.orderId!}
                        inviteTemplateID={
                            process.env.REACT_APP_PLATFORM_INVITE_TEMPLATE_ID ||
                            '0ca50895-e57f-4df4-b3bb-50ac4fae071c'
                        } // static ID as per Andrii Platform Team
                        shareLink={`${String(READ_ONLY_URL)}`}
                    />
                )}

                {/* DOWNLOAD - Only appears in read-only mode */}
                {isDownloadTranscript && (
                    <Tooltip label={`Download Transcript`} placement="top" hasArrow>
                        <Box>
                            <IconButton
                                variant="light"
                                icon={<DownloadIcon color={theme?.palette?.grey?.[10]} />}
                                // onClick={handleDownloadTranscript}
                                aria-label="download-link"
                                size="lg"
                                boxShadow="none"
                            />
                        </Box>
                    </Tooltip>
                )}

                {/* READ ONLY */}
                {isReadOnly && (
                    <Tooltip label={`Copy link to live transcript`} placement="top" hasArrow>
                        <Box>
                            <StyledIconButton
                                variant="light"
                                icon={<UploadIcon color={theme?.palette?.grey?.[10]} />}
                                onClick={handleShareURL}
                                aria-label="read-only-link"
                                size="lg"
                                boxShadow="none"
                            />
                        </Box>
                    </Tooltip>
                )}

                {/* MEDIA SOURCE - VerbitConnect, Zoom or Teams*/}
                <MediaSource setIsVCRecording={setIsVCRecording} />

                {/* ORDERING */}
                {isEndSessionVisible && (
                    <Button
                        variant="gradient"
                        colorScheme="gray"
                        onClick={handleStopEditing}
                        size="lg"
                        boxShadow="none"
                        isDisabled={disableStopButton}
                    >
                        Stop Editing
                    </Button>
                )}
            </ButtonGroup>
            <EndSessionModal isOpen={isEndSession} onClose={handleEndSession} />
        </StyledToolbar>
    )
}
