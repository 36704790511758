import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { IconButton } from '@verbit-ai/verbit-ui-library'
import { TrashIcon } from '@verbit-ai/icons-library'
import { PopoutSelect, Tooltip } from '@verbit-ai/verbit-ui-library'
import styled from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'
import { Editor } from 'slate'
import { ReactEditor } from 'slate-react'
import { Block } from 'src/components/Editor/plugins/withTranscript/Block'
import { TranscriptionTask } from 'src/models'
import AddCustomEvents from 'src/components/Editor/plugins/withTranscript/components/AddCustomEvents'
import { useEventsMarking } from 'src/state/EventsProvider'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'
import { useAutoScrollContext } from 'src/components/Session/live/components/LiveEditor/AutoScrollContainer'
import { useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'

interface EventItem {
    value: string
    label: string
}

const COLOURS = ['black', 'blue', 'grey']
const SPECIAL_WORDS = [`Party's`, `Party`, `XX`, `XX:XX`]
const ADD_CUSTOM_WORD = '( Add custom event )'

const Container = styled.div<{ hasSection: boolean }>`
    display: flex;
    align-items: center;
    user-select: none;
    margin-left: 270px;
    margin-bottom: ${ifProp('hasSection', '2px', '0')};

    & .select-control .bp4-button.bp4-minimal.bp4-disabled {
        cursor: auto;
    }

    & .bp4-button.bp4-minimal:focus {
        outline: none;
        background-color: ${palette('grey', 8)};
    }
`

const InputWrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    top: -15px;
    margin-bottom: 3px;
`

const StyledInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1px;
    font-size: 14px;
    background: transparent;
    color: transparent;
    caret-color: black;
    text-wrap: nowrap;
    white-space: nowrap;
    overflow: none;
    text-overflow: ellipsis;

    &:focus {
        border: 0.5px solid ${palette('blue', 1)};
        border-radius: 3px;
        outline: none;
    }
`

const ColoredTextDisplay = styled.div`
    padding: 1px;
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    pointer-events: none;

    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus,
    &:active {
        border: 1px solid ${palette('blue', 1)};
        border-radius: 3px;
    }

    &:selection {
        color: red;
    }
`

const ColoredSpan = styled.span<{ color: string }>`
    color: ${({ color }) => color};
`

const NoOptionsMessage = styled.div`
    padding: 8px 10px;
    margin-left: 10px;
    font-size: 14px;
    color: ${palette('grey', 6)};
`

const LineWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 10px 0;
    left: 15px;
`

const Line = styled.hr<{ hasWrapped: boolean }>`
    border: none;
    border-top: 1px solid ${palette('grey', 13)};
    margin: ${ifProp('hasWrapped', '-7px', '0')};
`

const EventName = styled.span<{ hasWrapped: boolean }>`
    position: absolute;
    top: ${ifProp('hasWrapped', '-7px', '0')};
    left: 20px;
    background-color: ${palette('white', 0)};
    padding: 0 15px;
    font-size: 14px;
    font-color: ${palette('grey', 8)};
    line-height: 1.2;
    margin-bottom: ${({ hasWrapped }) => (hasWrapped ? '10px' : '0')};
    white-space: ${({ hasWrapped }) => (hasWrapped ? 'normal' : 'nowrap')};
`

interface EventsBoxProps {
    element: Block
    editor: Editor
    section?: string
    controls: TranscriptionTask['payload']['controls']
    blockEditable: boolean
    onSectionChange: (section?: string) => void
    isCalibrationAnchorDisabled?: boolean
}

// EventsBox component is used to display the events marking (replacement for sections) box in the editor.
export const EventsBox = React.memo((props: EventsBoxProps) => {
    const {
        element,
        editor,
        section,
        controls,
        blockEditable,
        onSectionChange,
        isCalibrationAnchorDisabled,
    } = props
    const analytics = useAnalytics()
    const [scrollPosition, setScrollPosition] = useState(0)
    const [selectVisible, setSelectVisible] = useState(!!section)
    const [blockIndex] = ReactEditor.findPath(editor, element)
    const [inputValue, setInputValue] = useState('')
    const [isShowCustomEventTooltip, setIsShowCustomEventTooltip] = useState(false)
    const [isPopoutSelectOpen, setIsPopoutSelectOpen] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
    const [flipToTop, setFlipToTop] = useState(false)
    const componentRef = useRef<HTMLDivElement | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const { targetIndex } = useEventsMarking()
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const hasWrapped = inputValue.length > 70
    const { exitAutoScroll } = useAutoScrollContext(['exitAutoScroll'])

    useEffect(() => {
        if (componentRef.current) {
            componentRef.current.scrollTop = scrollPosition
        }
    }, [scrollPosition])

    useEffect(() => {
        setSelectVisible(!!section)
        setInputValue(section || '')
    }, [section])

    useEffect(() => {
        // let timeout: NodeJS.Timeout
        if (targetIndex === blockIndex) {
            setShowTooltip(true)
        }

        const timeout = setTimeout(() => {
            setShowTooltip(false)
        }, 2000)

        return () => {
            clearTimeout(timeout)
        }
    }, [blockIndex, targetIndex])

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0]
                const { bottom } = entry.boundingClientRect
                const windowHeight = window.innerHeight

                if (bottom > windowHeight - 350) {
                    setFlipToTop(true)
                } else {
                    setFlipToTop(false)
                }
            },
            {
                threshold: 1.0,
            },
        )

        const currentComponent = componentRef.current
        if (currentComponent) observer.observe(currentComponent)

        return () => {
            if (currentComponent) observer.unobserve(currentComponent)
        }
    }, [isPopoutSelectOpen, analytics])

    // color out the special words after selection
    // has been made in the select dropdown
    // special words are colored in blue to denote user that those values they might want to change
    // black is the default color and grey is for the custom event
    const colorizedText = inputValue
        .split(/([\s(),{}])/) // split by space, comma, parenthesis, and curly braces
        .filter(Boolean) // check for falsy values from resulting array
        .map((word, index) => {
            // map over the array generated by split
            let colorIndex = 0

            if (inputValue === ADD_CUSTOM_WORD) {
                colorIndex = 2
            } else if (SPECIAL_WORDS.includes(word)) {
                colorIndex = 1
            }

            return (
                <ColoredSpan
                    key={index}
                    color={COLOURS[colorIndex]}
                    style={{ fontStyle: colorIndex === 2 ? 'italic' : 'normal' }}
                >
                    {word}
                </ColoredSpan>
            )
        })

    const sectionSelectDisabled = !controls.legal.annotation.editable || !blockEditable
    const isActive = selectVisible
    const shouldShowRemoveButton = section && blockEditable

    const handleScroll = () => {
        if (componentRef.current) {
            setScrollPosition(componentRef.current.scrollTop)
        }
    }

    const handlePropagateEvent = (e: React.KeyboardEvent | React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value)
            onSectionChange(e.target.value)
        },
        [onSectionChange],
    )

    const handleEditableTextKeydown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
                handlePropagateEvent(e)
                setInputValue('')
                onSectionChange('')
            } else if (e.key === 'Enter') {
                handlePropagateEvent(e)
                ReactEditor.focus(editor)
            }
        },
        [editor, onSectionChange],
    )

    const handleEditableTextMouseLeave = useCallback(() => {
        if (inputValue === ADD_CUSTOM_WORD || inputValue === '' || inputValue === ' ') {
            setIsShowCustomEventTooltip(true)
        } else {
            setIsShowCustomEventTooltip(false)
        }
    }, [inputValue])

    const handlePopoutSelectKeydown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Tab') {
                handlePropagateEvent(e)
                setInputValue(ADD_CUSTOM_WORD)
                onSectionChange(ADD_CUSTOM_WORD)
                setTimeout(() => {
                    inputRef.current?.focus()
                })
            }
        },
        [onSectionChange],
    )

    const handlePopoutSelectMenuOpen = useCallback(() => {
        setIsPopoutSelectOpen(true)
        analytics?.sendEventTrigger(
            ANALYTICS_CONSTS.Features.EVENTS_MARKING,
            ANALYTICS_CONSTS.EventsMarking.EVENT_OPTIONS_OPENED,
        )
        exitAutoScroll()
    }, [exitAutoScroll, analytics])

    const handleEventSelection = useCallback(
        (item: EventItem) => {
            if (item) {
                onSectionChange(item.value)
                setInputValue(item.label)
                analytics?.sendEventTrigger(
                    ANALYTICS_CONSTS.Features.EVENTS_MARKING,
                    ANALYTICS_CONSTS.EventsMarking.DEFAULT_EVENT_ADDED,
                )
                setTimeout(() => {
                    inputRef.current?.focus()
                })
            }
        },
        [onSectionChange, analytics],
    )

    const handleAddCustomEvent = useCallback(
        (section: string) => {
            onSectionChange(section)
            setInputValue(section)
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.EVENTS_MARKING,
                ANALYTICS_CONSTS.EventsMarking.CUSTOM_EVENT_ADDED,
            )
            setTimeout(() => {
                inputRef.current?.focus()
            })
        },
        [onSectionChange, analytics],
    )

    const handleCancelEvent = useCallback(() => {
        onSectionChange('')
        setInputValue('')
        setIsShowCustomEventTooltip(false)
        analytics?.sendEventTrigger(
            ANALYTICS_CONSTS.Features.EVENTS_MARKING,
            ANALYTICS_CONSTS.EventsMarking.EDITOR_EVENT_REMOVED,
        )
    }, [onSectionChange, analytics])

    return (
        <Container
            ref={componentRef}
            hasSection={!!section}
            className="eventsbox"
            onScroll={handleScroll}
        >
            {!isRealTimeReadOnly &&
                !shouldShowRemoveButton &&
                controls?.events &&
                controls?.events?.items && (
                    <PopoutSelect
                        className={'add-events-' + blockIndex}
                        options={controls?.events?.items}
                        placeholder="Filter"
                        onChange={(option) => {
                            option && handleEventSelection(option as EventItem)
                        }}
                        onKeyDown={handlePopoutSelectKeydown}
                        onFocus={handlePopoutSelectMenuOpen}
                        popOutSelectLabel={!isActive ? 'Add event' : ''}
                        popOutVariant={'secondary'}
                        popOutSize="md"
                        menuPlacement={flipToTop ? 'top' : 'bottom'}
                        isDisabled={sectionSelectDisabled || isCalibrationAnchorDisabled}
                        components={{
                            Menu: createAddCustomEventsComponent(handleAddCustomEvent),
                            DropdownIndicator: null,
                            IndicatorSeparator: null,
                            NoOptionsMessage: (val) => (
                                <NoOptionsMessage>No results found</NoOptionsMessage>
                            ),
                            Placeholder: () => null,
                        }}
                        tabIndex={0}
                    />
                )}

            {!isRealTimeReadOnly && shouldShowRemoveButton && (
                <>
                    <Tooltip
                        label="Event location"
                        placement="left-start"
                        isOpen={showTooltip}
                        hasArrow
                    >
                        <InputWrapper>
                            <ColoredTextDisplay>{colorizedText}</ColoredTextDisplay>
                            <Tooltip
                                label="Please add an event label."
                                placement="left-start"
                                isOpen={isShowCustomEventTooltip}
                                hasArrow
                            >
                                <StyledInput
                                    ref={inputRef}
                                    className={'add-events-' + blockIndex}
                                    type="text"
                                    value={inputValue === ADD_CUSTOM_WORD ? '' : inputValue}
                                    maxLength={200}
                                    onMouseLeave={handleEditableTextMouseLeave}
                                    tabIndex={0}
                                    onChange={handleInputChange}
                                    onKeyDown={handleEditableTextKeydown}
                                    onFocus={() => {
                                        exitAutoScroll()
                                    }}
                                />
                            </Tooltip>
                        </InputWrapper>
                    </Tooltip>

                    <IconButton
                        variant="link"
                        icon={<TrashIcon />}
                        aria-label="bin-icon"
                        onClick={handleCancelEvent}
                    />
                </>
            )}

            {isRealTimeReadOnly && (
                <Tooltip
                    label="Event location"
                    placement="left-start"
                    isOpen={showTooltip}
                    hasArrow
                >
                    <LineWrapper>
                        <EventName hasWrapped={hasWrapped}>{inputValue}</EventName>
                        <Line hasWrapped={hasWrapped} />
                    </LineWrapper>
                </Tooltip>
            )}
        </Container>
    )
})

function createAddCustomEventsComponent(onSectionChange: (section: string) => void) {
    return (props: any) => {
        const MemoizedAddCustomEvents = useMemo(() => {
            return <AddCustomEvents onSectionChange={onSectionChange} {...props} />
        }, [props])

        return MemoizedAddCustomEvents
    }
}
