import React from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import * as Icons from 'src/components/icons'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { AttachmentsDownloadSource, useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'
import { getFileExtension } from 'src/utils/fileExtension'

const Heading = styled.div`
    display: flex;
    align-items: center;
    height: 54px;
    padding: 16px;
    color: ${palette('navy', 4)};
    font-size: 14px;
    font-weight: bold;
    border-bottom: solid 1px ${palette('cloudBlueLight', 5)};
`

const Content = styled.div`
    max-height: 220px;
    overflow-y: auto;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 16px;
    color: ${palette('navy', 5)};
    font-size: 14px;
    line-height: 15px;
    border-bottom: solid 1px ${palette('cloudBlueLight', 5)};
`

const AttachmentRow = styled(Row)`
    transition: color 250ms ease-out;

    a {
        color: inherit;
        text-decoration: none;
    }

    &:active,
    &:hover {
        color: ${palette('blue', 0)};
    }

    svg {
        width: 14px;
        height: 16px;
        margin-right: 6px;
    }
`

const DefaultAttachmentIcon = styled(Icons.DefaultAttachmentIcon)`
    color: #304571;
`

const ATTACHMENT_ICONS: { [fileType: string]: React.FC } = {
    doc: Icons.WordIcon,
    docx: Icons.WordIcon,
    pdf: Icons.PdfIcon,
}

// TODO: move
// TODO: update icons ?
export const getAttachmentIcon = (attachmentName: string) => {
    const fileType = attachmentName.substr(
        attachmentName.lastIndexOf('.') + 1,
        attachmentName.length,
    )
    const AttachmentIcon = ATTACHMENT_ICONS[fileType] ?? DefaultAttachmentIcon

    return <AttachmentIcon />
}

export function AttachmentsPanel() {
    const { attachments = [], guidelines } =
        useSessionStatus(['sessionStatus.attachments', 'sessionStatus.guidelines']).sessionStatus ??
        {}
    const analytics = useAnalytics()

    return (
        <div>
            {guidelines && (
                <>
                    <Heading>Guidelines</Heading>
                    <Content>
                        <AttachmentRow>
                            <DefaultAttachmentIcon />
                            <a
                                onClick={() =>
                                    analytics?.sendEventTrigger(
                                        ANALYTICS_CONSTS.Features.ATTACHMENTS,
                                        ANALYTICS_CONSTS.Legacy.GUIDELINES_ATTACHMENTS_OPENED,
                                    )
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                href={guidelines || undefined}
                            >
                                Session Guidelines
                            </a>
                        </AttachmentRow>
                    </Content>
                </>
            )}

            <Heading data-testid={'attachments-heading'}>Attachments</Heading>
            <Content>
                {!attachments || attachments.length === 0 ? (
                    <Row>No attachments found.</Row>
                ) : (
                    attachments?.map(({ name, url }) => (
                        <AttachmentRow key={`${name}-${url}`}>
                            {getAttachmentIcon(name)}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={url}
                                onClick={() =>
                                    analytics?.sendAttachmentDownloaded(
                                        getFileExtension(url).toLowerCase(),
                                        AttachmentsDownloadSource.PANEL,
                                    )
                                }
                            >
                                {name}
                            </a>
                        </AttachmentRow>
                    ))
                )}
            </Content>
        </div>
    )
}
