import { useState, useRef, useEffect } from 'react'

const useVisibility = (
    root: Element | null,
    rootMargin?: string,
    initialIsVisible: boolean = false,
) => {
    const [isVisible, setIsVisible] = useState(initialIsVisible)
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const el = ref.current
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting)
            },
            {
                root: root,
                rootMargin: rootMargin,
            },
        )

        if (el) {
            observer.observe(el)
        }

        return () => {
            if (el) {
                observer.unobserve(el)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current])

    return { isVisible, visibilityRef: ref }
}

export default useVisibility
