import { Hotkey } from '../models/hotkeys'
import { isMac } from './platform'

const excludedHotkeys = [
    'enter',
    'esc',
    'command+s',
    'ctrl+s',
    'tab',
    'ctrl+c',
    'ctrl+v',
    'command+c',
    'command+v',
    'command+r',
    'command+z',
    'ctrl+r',
    'ctrl+z',
    'command+f',
    'ctrl+f',
]

export const appHotkeys = {
    togglePlay: isMac ? 'alt+space' : 'ctrl+space',
    seekBackward: isMac ? 'command+left' : 'ctrl+left',
    seekForward: isMac ? 'command+right' : 'ctrl+right',
    playFromEditable: 'alt+s',
    increasePlaybackSpeed: 'alt+.',
    decreasePlaybackSpeed: 'alt+,',
    playFromCaret: isMac ? 'command+i' : 'ctrl+i',
    markUnclear: 'alt+/',
    markClear: isMac ? 'command+u' : 'ctrl+u',
    openExhibit: isMac ? 'command+alt+x' : 'ctrl+alt+x',
    openSpeakerSelect: isMac ? 'command+shift+s' : 'ctrl+shift+s',
    annotateQuestion: 'alt+q',
    annotateAnswer: 'alt+a',
    annotateColloquy: 'alt+c',
    addExhibit: 'alt+x',
    publishTask: 'alt+p',
    openAddSpeaker: isMac ? 'command+shift+a' : 'ctrl+shift+a',
    openGlossary: isMac ? 'command+g' : 'ctrl+g',
    openContextMenu: isMac ? 'alt+d' : 'ctrl+d',
}

/**
 * Check if hotkey keys already in use
 */
export const isHotkeyExists = (
    hotkey: string,
    hotkeyId: string = '',
    userHotkeys: Hotkey[] = [],
) => {
    const userHotkeysValues = userHotkeys
        .filter((userHotkey) => userHotkey.id !== hotkeyId)
        .map((userHotkey) => userHotkey.value.toLowerCase())

    const appHotkeyValues = new Set(
        Object.values(appHotkeys).concat(excludedHotkeys).concat(userHotkeysValues),
    )

    return appHotkeyValues.has(hotkey.toLowerCase())
}
/**
 * Check if hotkey name is already exist
 */
export const isNameExists = (name: string, hotkeyId = '', userHotkeys: Hotkey[] = []): boolean => {
    return !!userHotkeys.find(
        (hotkey) => hotkey.name.toLowerCase() === name.toLowerCase() && hotkey.id !== hotkeyId,
    )
}

/**
 * Translate keyboard event to string
 */
export const eventToString = (e: KeyboardEvent) => {
    const keys = []

    if (e.ctrlKey) keys.push('Ctrl')
    if (e.altKey) isMac ? keys.push('Option') : keys.push('Alt')
    if (e.metaKey) keys.push('Command')
    if (e.shiftKey) keys.push('Shift')

    if (e.key.length === 1) {
        keys.push(e.key.toUpperCase())
    } else if (e.key.startsWith('Arrow')) {
        keys.push(e.key.slice(5))
    } else if (e.key !== 'Control' && e.key !== 'Shift' && e.key !== 'Alt' && e.key !== 'Meta') {
        keys.push(e.key)
    }

    return keys.join('+')
}

/**
 * Hotkey validation - limit options for hotkey to keep app functionality.
 * Return error or empty string if valid
 */
export const validateHotkey = (
    hotkey: string,
    hotkeyId: string = '',
    userHotkeys: Hotkey[] = [],
): string => {
    if (!hotkey?.match(/(Ctrl|Alt|Shift|Command|Option)\+|^F([1-9]|1[0-2])$/)) {
        return 'Input must include control/Fn'
    }

    if (hotkey?.match(/^Shift\+[\w`~!@#$%^&*()\-_=+{};:'",<.>/?|\\ ]$/)) {
        return 'Do not use Shift + character'
    }

    if (isHotkeyExists(hotkey, hotkeyId, userHotkeys)) {
        return 'This shortcut is already in use'
    }

    return ''
}
