import { Modal, Typography, Button, useToast, UseToastOptions } from '@verbit-ai/verbit-ui-library'
import { HStack } from '@chakra-ui/react'
import { useInvites } from 'src/components/Invites'
import { InviteModalState } from '../Invites/constants'
import { removeInvite } from '../Invites/api/apiHelpers'
import { RemoveInviteProps, RemoveInvitee } from './types'

const RemoveInvitePrompt = (props: RemoveInviteProps) => {
    const { isOpen, onClose } = props
    const { setInviteModalState, apiToken, setRemoveInvitee, removeInvitee } = useInvites()
    const toast = useToast()

    const handleCancel = () => {
        setInviteModalState(InviteModalState.LIST)
    }

    const handleRemoveInvite = async () => {
        if (!apiToken) return
        if (!removeInvitee) return

        try {
            await removeInvite(removeInvitee.id, apiToken)

            toast({
                id: 'remove-invite-toast',
                type: 'info',
                title: `${
                    removeInvitee.name || removeInvitee.email
                } has been removed from the invited list`,
                isClosable: true,
            } as UseToastOptions)

            setRemoveInvitee({} as RemoveInvitee)
            setInviteModalState(InviteModalState.LIST)
            onClose()
        } catch (error) {
            toast({
                id: 'remove-invite-error-toast',
                type: 'error',
                title: 'Something went wrong. Try again.',
                isClosable: true,
            } as UseToastOptions)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={'sm'}>
            <Modal.CloseButton />
            <Modal.Header>
                {props.modalRemoveInviteTitle || 'Remove access to live transcript?'}
            </Modal.Header>
            <Modal.Body>
                <Typography>
                    {`${
                        removeInvitee.name || removeInvitee.email
                    } will immediately lose access to the deposition live transcript if
                    you continue.`}
                </Typography>
            </Modal.Body>
            <Modal.Footer>
                <HStack spacing="5px">
                    <Button variant="secondary" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button onClick={handleRemoveInvite}>Remove</Button>
                </HStack>
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveInvitePrompt
