export const ANALYTICS_CONSTS = {
    /* Applicaton Features */
    Features: {
        TOP_BAR: 'Top Bar',
        GLOSSARY: 'Glossary',
        SPEAKERS: 'Speakers',
        CONTEXT_MENU: 'Context Menu',
        EVENTS_MARKING: 'Events Marking',
        ANNOTATION: 'Annotation',
        EXHIBITS: 'Exhibits',
        TAB_PANEL: 'Tab Panel',
        ATTACHMENTS: 'Attachments',
        EDITOR: 'Editor',
        MENU: 'Editor Menu',
        HOTKEYS: 'Editor Hotkeys',

        // - Legacy Features
        CHAT: 'Chat',
        ONBOARDING: 'Onboarding',
        TAKE_A_BREAK: 'Editor Take a Break',
        VALIDATION: 'Validation',
        AUDIO_PANEL: 'Audio Panel',
    },

    /* MIXPANEL EVENTS MESSAGES */
    Legacy: {
        AUDIO_SPEED_CHANGE: 'Audio speed change',
        STEP_NAVIGATION: 'Step navigation',
        MESSAGE_SENT: 'Message sent',
        TASK_ASSIGNED_TO_USER: 'Task assigned to user',
        TASK_COMPLETED: 'Task completed',
        TASK_SUBMITTED: 'Task submitted',
        ACCEPT_SUGGESTION: 'Accept suggestion',
        SHOW_SUGGESTION: 'Show suggestion',
        REMOVE_SUGGESTION: 'Remove suggestion',
        SPEAKERS_SEPARATION_COUNT: 'Speakers separation count',
        VALIDATION_BEFORE_AFTER_STRINGS: 'Validation before after strings',
        GUIDELINES_ATTACHMENTS_OPENED: 'Guidelines attachments opened',
        VALIDATION_TRIGGERED: 'Validation triggered',
        REPORT_VALIDATION: 'Report validation',
    },

    Operation: {
        ADD_A_TERM: 'Add a term',
        DELETE_A_TERM: 'Delete a term',
        EDIT_A_TERM: 'Edit a term',
        USE_A_TERM: 'Use a term',
        MARK_UNCLEAR: 'Mark unclear',
        OPEN_GLOSSARY: 'Open Glossary',
        GLOSSERS_TRY_TO_EDIT: 'Glossers try to edit',
        FILE_DOWNLOADED: 'File downloaded',
        PARAGRAPH_MERGE: 'Paragraph merge',
        PARAGRAPH_DELETE: 'Paragraph delete',
        PARAGRAPH_EDITED: 'Paragraph edited',
        PARAGRAPH_BREAK: 'Paragraph break',
        PLAY_FROM_HERE: 'Play from here',
        LEGAL_ANNOTATION_CHANGE: 'Legal Annotation change',
        EXHIBIT_CHANGE: 'Exhibit change',
    },

    Session: {
        USER_SETTINGS_CLICKED: 'User menu settings clicked',
        USER_JOIN_SESSION: 'User Joins a Session',
        USER_LEAVE_SESSION: 'User Leaves the Session',
        OPEN_BREAK_OVERLAY: 'Open break overlay',
        SESSION_START: 'Session start',
        SESSION_STOPPED: 'Session stopped',
        SESSION_RESUMED: 'Session resumed',
        BACKGROUND_TRANSCRIPT_REFRESH: 'Background transcript refresh',
        SHARE_LINK_CLICKED: 'Share link clicked',
        STOP_EDITING_CLICKED: 'Stop editing clicked',
        END_SESSION_CLICKED: 'End session clicked',
    },

    EventsMarking: {
        SEARCH_EVENT_FILTERED: 'Search event filtered',
        EVENTS_SEARCH_FILTER_CLOSED: 'Events search filter closed',
        EVENTS_SEARCH_FILTER_CLOSED_BY_ESCAPE: 'Events search filter closed by escape key',
        GO_TO_TARGET_EVENT_CLICKED: 'Go to target event clicked',
        EVENT_OPTIONS_OPENED: 'Event options opened',
        DEFAULT_EVENT_ADDED: 'Default event added',
        CUSTOM_EVENT_ADDED: 'Custom event added',
        EDITOR_EVENT_REMOVED: 'Editor event marking has been removed',
    },

    Speakers: {
        SPEAKER_ADDED: 'Speaker added',
        SPEAKER_CHANGED: 'Speaker changed',
        SPEAKER_CREATED: (name: string) => `Speaker created: ${name}`,
        SPEAKER_CREATE_ERROR: ' Error creating speaker',
        SPEAKER_VOICE_SAMPLE_TOGGLED: 'Speaker voice sample toggled',
        SPEAKER_VOICE_SAMPLE_SET_DEFAULT: 'Speaker voice sample set as default',
        SPEAKER_VOICE_SAMPLE_DELETED: 'Speaker voice sample deleted',
    },

    Hotkeys: {
        KEYBOARD_SHORTCUT_USED: 'Keyboard shortcut used',
        HOTKEY_CUSTOM_SHORTCUT_ADDED: (name: string) =>
            `Custom keyboard shortcut added - (${name})`,
        HOTKEY_CUSTOM_SHORTCUT_REMOVED: 'Custom keyboard shortcut deleted',
    },
}
