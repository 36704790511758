import { useEffect, useState } from 'react'
import {
    LiveMachineState,
    useLiveMachine,
} from 'src/state/state-machines/LiveMachine/LiveMachineProvider'
import { LivePanelTabsManager } from './components/LivePanelTabsManager/LivePanelTabsManager'
import { LivePanelTabsHeader } from './components/LivePanelTabsManager/LivePanelTabsHeader'
import { LiveEditor } from './components/LiveEditor/LiveEditor'
import { LivePanelFooter } from './components/LivePanelFooterSection/LivePanelFooter'
import { Container, LiveBodyContainer } from './styles'
import { useSessionMedia } from 'src/state/SessionMediaProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useToast } from 'src/components/Toasts/ToastContext'
import { Intent, Icon } from '@blueprintjs/core'
import { SessionPlaybackState, SessionStatusState } from 'src/models'
import { InitializingScreen } from './InitializingScreen'
import { useIsReadOnlyMode } from './useIsReadOnlyMode'
import { useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'

type ToastType = 'PLAYING_ACTIVE' | 'RECORDING_STOPPED' | 'SESSION_FINISHED' | 'SESSION_RESUMED'

export const LiveSession = () => {
    const addToast = useToast()
    const analytics = useAnalytics()
    const [playCounter, setPlayCounter] = useState(0)
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const [{ value: state }] = useLiveMachine(['state', 'liveTranscript'])
    const { sessionStatus } = useSessionStatus([
        'sessionStatus.playbackState',
        'sessionStatus.state',
    ])
    const { connectMedia, isMediaConnected } = useSessionMedia(['connectMedia', 'isMediaConnected'])

    useEffect(() => {
        if (!isMediaConnected && sessionStatus?.playbackState === SessionPlaybackState.PLAYING) {
            connectMedia()
        }
    }, [connectMedia, isMediaConnected, sessionStatus?.playbackState])

    const handlePlayCounter = () => setPlayCounter(playCounter + 1)

    useEffect(() => {
        if (!isRealTimeReadOnly) return
        const showToast = (type: ToastType) => {
            addToast({
                intent: Intent.NONE,
                icon: <Icon icon="info-sign" color="blue" style={{ marginTop: '17px' }} />,
                message: (
                    <span>
                        <strong>{getMessageForToast(type)}</strong>
                    </span>
                ),
                timeout: 8000,
            })
        }

        if (
            state === 'working' &&
            sessionStatus?.playbackState === SessionPlaybackState.PLAYING &&
            sessionStatus?.state === SessionStatusState.READY
        ) {
            showToast('PLAYING_ACTIVE')
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.EDITOR,
                ANALYTICS_CONSTS.Session.SESSION_START,
            )
            handlePlayCounter()
        } else if (
            state === 'working' &&
            sessionStatus?.playbackState === SessionPlaybackState.STOPPED &&
            (sessionStatus?.state === SessionStatusState.READY ||
                sessionStatus?.state === SessionStatusState.ACTIVE)
        ) {
            showToast('RECORDING_STOPPED')
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.EDITOR,
                ANALYTICS_CONSTS.Session.SESSION_STOPPED,
            )
        } else if (state === 'working' && sessionStatus?.state === SessionStatusState.FINISHED) {
            showToast('SESSION_FINISHED')
        } else if (
            state === 'working' &&
            sessionStatus?.playbackState === SessionPlaybackState.PLAYING &&
            sessionStatus?.state !== SessionStatusState.FINISHED &&
            playCounter >= 1
        ) {
            showToast('SESSION_RESUMED')
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.EDITOR,
                ANALYTICS_CONSTS.Session.SESSION_RESUMED,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStatus?.playbackState, sessionStatus?.state, addToast, state])

    return (
        <Container>
            <LivePanelTabsHeader />
            <LiveBodyContainer>
                {renderSession(state)}
                <LivePanelTabsManager />
            </LiveBodyContainer>
            <LivePanelFooter />
        </Container>
    )
}

function renderSession(state: LiveMachineState['value']) {
    switch (state) {
        case 'initializing':
            return <InitializingScreen />
        case 'working':
            return <LiveEditor />
    }
}

const getMessageForToast = (type: ToastType): string => {
    switch (type.toLocaleUpperCase()) {
        case 'PLAYING_ACTIVE':
            return 'Session is already active.'
        case 'RECORDING_STOPPED':
            return 'Recording has ended.'
        case 'SESSION_FINISHED':
            return 'Thank you for joining, the session has ended.'
        case 'SESSION_RESUMED':
            return 'Recording resumed.'
        default:
            return ''
    }
}
