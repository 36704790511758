import { MethodType } from '../types/types'

// CONSTANTS
export const HTTP_METHODS: { [key: string]: MethodType } = {
    GET: 'GET' as MethodType,
    POST: 'POST' as MethodType,
    PUT: 'PUT' as MethodType,
    DELETE: 'DELETE' as MethodType,
}
export const INVITE_PLATFORM_BASE_ROUTE_URL = '/api/v1/invitations'
export const API_BEARER_NAME = 'ApiToken '

// ENUMS
// InviteModalState - different UI states of the invite modal
// INVITE_FORM - Form to invite users
// INVITE_LIST - List of invited users
// REMOVE_INVITE_PROMPT - Prompt to remove an invited user
export enum InviteModalState {
    FORM = 'INVITE_FORM',
    LIST = 'INVITE_LIST',
    REMOVE_INVITE = 'REMOVE_INVITE_PROMPT',
}

export const EMAIL_REGEX_PATTERN =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
