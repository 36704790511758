import { useRoleHotkeys } from 'src/hooks/settings/useRoleHotkeys'
import { Hotkey } from 'src/models/hotkeys'
import { AddHotkeyForm } from './AddHotkeyForm'
import { Divider, List, ListItem, Stack } from '@chakra-ui/react'
import styled from 'styled-components/macro'
import { HotkeyItem } from './HotkeyItem'

export const StyledInputStack = styled(Stack)`
    width: 192px;
`

const StyledDivider = styled(Divider)`
    margin: 22px 0;
`

const StyledList = styled(List)`
    max-height: 40vh;
    overflow-y: scroll;
    padding-right: 20px;
`

export const HotkeysTab = () => {
    const { roleHotkeys, customRole, addRoleHotkeys } = useRoleHotkeys()

    const addRole = async (hotkey: Hotkey) => {
        await addRoleHotkeys(hotkey)
    }

    return (
        <>
            <AddHotkeyForm onSubmit={addRole} hotkeyName={customRole} />
            <StyledDivider />
            <StyledList>
                {roleHotkeys &&
                    roleHotkeys.map((hotkey) => (
                        <ListItem key={hotkey.id}>
                            <HotkeyItem hotkey={hotkey} />
                        </ListItem>
                    ))}
            </StyledList>
        </>
    )
}
