import { createContext, useState, useContext, ReactNode, useMemo } from 'react'
import { InviteModalState } from '../constants'
import { CustomerUser, InvitedLists, RemoveInvitee } from '../types'
interface InvitesContextvalue {
    inviteModalState: InviteModalState
    setInviteModalState: React.Dispatch<React.SetStateAction<InviteModalState>>
    customerUsers: CustomerUser[]
    setCustomerUsers: React.Dispatch<React.SetStateAction<CustomerUser[]>>
    inviteList: string[]
    setInviteList: React.Dispatch<React.SetStateAction<string[]>>
    invitedViewers: InvitedLists[]
    setInvitedViewers: React.Dispatch<React.SetStateAction<InvitedLists[]>>
    apiToken: string
    setApiToken: React.Dispatch<React.SetStateAction<string>>
    orderId: string
    setOrderId: React.Dispatch<React.SetStateAction<string>>
    inviteTemplateID: string
    setInviteTemplateID: React.Dispatch<React.SetStateAction<string>>
    removeInvitee: RemoveInvitee
    setRemoveInvitee: React.Dispatch<React.SetStateAction<RemoveInvitee>>
}

const InvitesContext = createContext<InvitesContextvalue | undefined>(undefined)

interface InvitesProviderProps {
    children: ReactNode
}

const InvitesProvider = ({ children }: InvitesProviderProps) => {
    const [inviteModalState, setInviteModalState] = useState<InviteModalState>(
        InviteModalState.FORM,
    )
    const [customerUsers, setCustomerUsers] = useState<CustomerUser[]>([])
    const [inviteList, setInviteList] = useState<string[]>([])
    const [invitedViewers, setInvitedViewers] = useState<InvitedLists[]>([])
    const [apiToken, setApiToken] = useState('')
    const [orderId, setOrderId] = useState('')
    const [inviteTemplateID, setInviteTemplateID] = useState('')
    const [removeInvitee, setRemoveInvitee] = useState<RemoveInvitee>({} as RemoveInvitee)

    return (
        <InvitesContext.Provider
            value={useMemo(
                () => ({
                    inviteModalState,
                    setInviteModalState,
                    customerUsers,
                    setCustomerUsers,
                    inviteList,
                    setInviteList,
                    invitedViewers,
                    setInvitedViewers,
                    apiToken,
                    setApiToken,
                    orderId,
                    setOrderId,
                    inviteTemplateID,
                    setInviteTemplateID,
                    removeInvitee,
                    setRemoveInvitee,
                }),
                [
                    inviteModalState,
                    setInviteModalState,
                    customerUsers,
                    setCustomerUsers,
                    inviteList,
                    setInviteList,
                    invitedViewers,
                    setInvitedViewers,
                    apiToken,
                    setApiToken,
                    orderId,
                    setOrderId,
                    inviteTemplateID,
                    setInviteTemplateID,
                    removeInvitee,
                    setRemoveInvitee,
                ],
            )}
        >
            {children}
        </InvitesContext.Provider>
    )
}

const useInvites = () => {
    const invitesContextValue = useContext(InvitesContext)
    if (!invitesContextValue) throw new Error('useInvites must be used within InvitesProvider.')
    return invitesContextValue
}

export { InvitesProvider, useInvites }
