import { useState, useEffect } from 'react'
import { eventToString, validateHotkey } from 'src/utils/hotkeys'
import { useRoleHotkeys } from 'src/hooks/settings/useRoleHotkeys'
import { Input } from '@verbit-ai/verbit-ui-library'

import { Stack } from '@chakra-ui/react'
import { InputError } from './InputError'

type HotkeyInputProps = {
    onBlur?: (value: string) => void
    initialValue?: string
    hotkeyId?: string
    onErrorChange?: (id: string, hasError: boolean) => void
    onChange?: (value: string) => void
    autoFocus?: boolean
}

export function HotkeyInput({
    onBlur,
    initialValue,
    hotkeyId,
    onErrorChange,
    onChange,
    autoFocus,
}: HotkeyInputProps) {
    const [inputValue, setInputValue] = useState<string>(initialValue || '')
    const [error, setError] = useState('')
    const { roleHotkeys } = useRoleHotkeys()

    useEffect(() => {
        // reset value upon submit
        !initialValue && setInputValue('')
    }, [initialValue])

    // Translate keyboard events to readable key names
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Tab') return

        e.preventDefault()

        if (e.key === 'Backspace') {
            setInputValue('')
            setError('Field cannot be empty')
            onErrorChange && onErrorChange('hotkey', true)
            return
        }

        const hotkey = eventToString(e as KeyboardEvent)
        setInputValue(hotkey)
        onChange && onChange(hotkey)

        validate(hotkey)
    }

    const validate = (currentHotkey: string) => {
        const validationError = validateHotkey(currentHotkey, hotkeyId, roleHotkeys)
        if (validationError) {
            setError(validationError)
            onErrorChange && onErrorChange('hotkey', true)
            return
        }
        setError('')
        onErrorChange && onErrorChange('hotkey', false)
    }

    const handleBlur = () => {
        onBlur && onBlur(inputValue)
    }

    return (
        <Stack>
            <Input
                type="text"
                name={'hotkey'}
                value={inputValue}
                onKeyDown={(e) => handleKeyDown(e as unknown as KeyboardEvent)}
                onBlur={handleBlur}
                placeholder="Enter hotkey"
                size={'lg'}
                isInvalid={!!error}
                isFullWidth
                isReadOnly
                autoFocus={autoFocus}
            />
            {error && <InputError error={error} />}
        </Stack>
    )
}

export default HotkeyInput
