import { useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { theme, ifProp } from 'styled-tools'
import { Block } from 'src/components/Editor/plugins/withTranscript/Block'
import { EditorControls, LegalExhibit, LegalMetadata, MenuItemInterface, Speaker } from 'src/models'
import { useSpeakersById } from 'src/state/SpeakersProvider'
import { ExaminationLegalAnnotation } from 'src/components/Editor/plugins/withTranscript/Block'
import { SpeakerSelect } from './SpeakerSelect'
import { LegalAnnotationSelect } from './LegalAnnotationSelect'
import { AddExhibit } from './AddExhibit'
import { WarningDialog } from './WarningDialog'
import { useFeatureFlag } from 'src/state/FeatureFlagProvider'
import { useAnalytics, ANALYTICS_CONSTS } from 'src/analytics'

const Container = styled.div<{ isTimeCodeVisible: any }>`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: ${theme('sizes.speakerBoxWidth')}px;
    margin-top: ${ifProp('isTimeCodeVisible', '17.5px', '0')};
    margin-right: 15px;
    user-select: none;
`

const ExhibitColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 24px;
    width: 24px;
    height: 24px;
    margin-right: 6px;
`

const LegalAndSpeakerSelectContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 0px 12px;
    width: 224px;
`

interface SpeakerBoxProps {
    element: Block
    editor: any
    selectedSpeakerId: string | null
    speakerMarkedAsUnknown?: boolean
    legal?: LegalMetadata
    controls: EditorControls
    isBlockEditable: boolean
    pendingLegalAnnotation: ExaminationLegalAnnotation | null
    onSpeakerChange: (speaker: Speaker | null) => void
    onLegalAnnotationChange: (annotation: string) => void
    onLegalExhibitChange: (exhibit?: LegalExhibit) => void
    onAcceptPendingLegalAnnotations: () => void
    onCancelPendingLegalAnnotation: () => void
    timeCodeVisible?: boolean | null
    isRealTimeReadOnly?: boolean
}

export const SpeakerBox = ({
    element,
    editor,
    selectedSpeakerId,
    speakerMarkedAsUnknown,
    legal,
    controls,
    isBlockEditable,
    pendingLegalAnnotation,
    onSpeakerChange,
    onLegalAnnotationChange,
    onLegalExhibitChange,
    onAcceptPendingLegalAnnotations,
    onCancelPendingLegalAnnotation,
    timeCodeVisible,
    isRealTimeReadOnly,
}: SpeakerBoxProps) => {
    const eventsMarkingFlag = useFeatureFlag('add_events_marking')
    const speakersById = useSpeakersById()
    const analytics = useAnalytics()
    const selectedSpeaker = selectedSpeakerId ? speakersById[selectedSpeakerId] : null
    const speakerSelectDisabled = !controls.speaker.editable || !isBlockEditable
    const annotationItems = controls.legal.annotation.items
    const exhibitVisible = (isBlockEditable || !!legal?.exhibit) && controls.legal.exhibit.visible
    const exhibitDisabled = !isBlockEditable

    const { legalAnnotationDisabled } = useMemo<{
        legalAnnotationDisabled: boolean
    }>(() => {
        if (!controls.legal.annotation.editable || !isBlockEditable) {
            return {
                legalAnnotationDisabled: true,
            }
        }

        return {
            legalAnnotationDisabled: false,
        }
    }, [controls, isBlockEditable])

    const handleLegalAnnotationSelection = useCallback(
        (item: MenuItemInterface) => {
            onLegalAnnotationChange(item.key)
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.ANNOTATION,
                ANALYTICS_CONSTS.Operation.LEGAL_ANNOTATION_CHANGE,
            )
        },
        [onLegalAnnotationChange, analytics],
    )

    const handleExhibitChange = useCallback(
        (exhibit?: LegalExhibit) => {
            onLegalExhibitChange(exhibit)
            analytics?.sendEventTrigger(
                ANALYTICS_CONSTS.Features.EXHIBITS,
                ANALYTICS_CONSTS.Operation.EXHIBIT_CHANGE,
            )
        },
        [onLegalExhibitChange, analytics],
    )

    const onSpeakerSelection = (speaker: Speaker | null) => {
        onSpeakerChange(speaker)
        analytics?.sendEventTrigger(
            ANALYTICS_CONSTS.Features.SPEAKERS,
            ANALYTICS_CONSTS.Speakers.SPEAKER_CHANGED,
        )
    }

    return (
        <Container
            contentEditable={false}
            className="speakerbox"
            isTimeCodeVisible={timeCodeVisible && !isRealTimeReadOnly}
        >
            <ExhibitColumn>
                {!eventsMarkingFlag && exhibitVisible && (
                    <AddExhibit
                        disabled={exhibitDisabled}
                        exhibit={legal?.exhibit}
                        onChange={handleExhibitChange}
                        isRealTimeReadOnly={isRealTimeReadOnly}
                        element={element}
                        editor={editor}
                    />
                )}
            </ExhibitColumn>

            <WarningDialog
                isOpen={!!pendingLegalAnnotation}
                text={`Is ${selectedSpeaker?.name} ${
                    pendingLegalAnnotation === ExaminationLegalAnnotation.Q
                        ? 'asking the questions'
                        : 'answering'
                } from this point?`}
                acceptLabel="Confirm"
                declineLabel="Cancel"
                onAccept={onAcceptPendingLegalAnnotations}
                onDecline={onCancelPendingLegalAnnotation}
            >
                <LegalAndSpeakerSelectContainer>
                    {controls.legal.annotation.visible && (
                        <LegalAnnotationSelect
                            element={element}
                            editor={editor}
                            items={annotationItems}
                            value={legal?.annotation}
                            onChange={handleLegalAnnotationSelection}
                            disabled={legalAnnotationDisabled}
                            isRealTimeReadOnly={isRealTimeReadOnly}
                        />
                    )}

                    {controls.speaker.visible && (
                        <SpeakerSelect
                            element={element}
                            editor={editor}
                            selectedSpeakerId={selectedSpeakerId}
                            onChange={onSpeakerSelection}
                            disabled={speakerSelectDisabled}
                            markedAsUnknown={speakerMarkedAsUnknown}
                            isRealTimeReadOnly={isRealTimeReadOnly}
                        />
                    )}
                </LegalAndSpeakerSelectContainer>
            </WarningDialog>
        </Container>
    )
}
