import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { TopBar } from 'src/components/App/TopBar'
import { SomethingWentWrongIcon } from 'src/components/icons'
import { ErrorScreen } from './ErrorScreen'

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    flex: 1;
    flex-direction: column;
    background-color: ${palette('cloudBlueLight', 7)};
`

export function ErrorFallbackScreen() {
    return (
        <Container>
            <TopBar isFromFallBackScreen={true} />
            <ErrorScreen
                icon={<SomethingWentWrongIcon />}
                title="Something Went Wrong"
                subtitle={
                    <>
                        Try refreshing the page.
                        <br />
                        If the problem persists contact{' '}
                        <a href="mailto:support@verbit.ai">support</a>.
                    </>
                }
                actionTitle="Refresh Page"
                action={() => globalThis.location.reload()}
            />
        </Container>
    )
}
