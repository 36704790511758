import { useEffect } from 'react'
import { IconButton, Modal, Typography } from '@verbit-ai/verbit-ui-library'
import { LeftChevronIcon, TrashIcon } from '@verbit-ai/icons-library'
import { VStack, HStack, Spacer, Text, Box } from '@chakra-ui/react'
import { useInvites } from 'src/components/Invites'
import { InviteModalState } from './constants'
import { theme } from '@verbit-ai/verbit-ui-library'

interface StyledInviteStatusProps {
    children: React.ReactNode
}

interface StyledInvitedListScrollableDivProps {
    children: React.ReactNode
}

const StyledInviteStatus: React.FC<StyledInviteStatusProps> = (props) => {
    return (
        <Text
            color={theme.colors.blue[1100]}
            fontSize="14px"
            textDecoration="none"
            as="span"
            {...props}
        >
            {props.children}
        </Text>
    )
}

const StyledInvitedListScrollableDiv: React.FC<StyledInvitedListScrollableDivProps> = (props) => {
    return (
        <Box
            width="100%"
            height="240px"
            overflowY="auto"
            overflowX="hidden"
            paddingRight="10px"
            {...props}
        >
            {props.children}
        </Box>
    )
}

const InvitedViewersList = () => {
    const { setInviteModalState, invitedViewers, setRemoveInvitee } = useInvites()

    const handleRemoveInvite = (id: number, name: string, email: string) => {
        setRemoveInvitee({ id, name, email })
        setInviteModalState(InviteModalState.REMOVE_INVITE)
    }

    useEffect(() => {
        if (invitedViewers.length === 0) {
            setInviteModalState(InviteModalState.FORM)
        }
    }, [invitedViewers, setInviteModalState])

    return (
        <>
            <Modal.CloseButton />
            <Modal.Header>
                <IconButton
                    variant="link"
                    icon={<LeftChevronIcon />}
                    aria-label="back-to-form"
                    onClick={() => setInviteModalState(InviteModalState.FORM)}
                />
                Invited viewers
            </Modal.Header>
            <Modal.Body>
                <hr />
                <StyledInvitedListScrollableDiv>
                    {invitedViewers.map((item, index) => (
                        <VStack key={index} spacing="5px" ml={3} mt={3} align={'flex-start'}>
                            <HStack spacing="5px" width="100%">
                                <VStack spacing="5px" align={'flex-start'}>
                                    <Typography variant="h6" color={theme.colors.blue[1100]}>
                                        {item.name || `--`}
                                    </Typography>
                                    <Typography size="sm" color={theme.colors.grey[900]}>
                                        {item.email}
                                    </Typography>
                                </VStack>
                                <Spacer />
                                <StyledInviteStatus>
                                    {item?.is_used ? 'Viewing' : 'Invited'}
                                </StyledInviteStatus>
                                <IconButton
                                    variant="link"
                                    icon={<TrashIcon />}
                                    aria-label="remove-invite-icon"
                                    onClick={() =>
                                        handleRemoveInvite(item.id, item?.name || '', item.email)
                                    }
                                    style={{ padding: 0, marginTop: 0 }}
                                />
                            </HStack>
                        </VStack>
                    ))}
                </StyledInvitedListScrollableDiv>
            </Modal.Body>
        </>
    )
}

export default InvitedViewersList
