import { useContext, useEffect, ReactNode, createContext } from 'react'
import LogRocket from 'logrocket'

import { useAnalytics } from 'src/analytics'
import { getEnv } from 'src/utils/env'
import { EditorControls, LiveTranscriptionLayerId } from 'src/models'
import { SpeakersProvider } from 'src/state/SpeakersProvider'
import { ExhibitsProvider } from './ExhibitsProvider'
import { EventsMarkingProvider } from './EventsProvider'
import { SpeakerVoiceSamplePlayerProvider } from 'src/state/SpeakerVoiceSamplePlayerProvider'
import { LegalAnnotationsProvider } from 'src/state/LegalAnnotationsProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { TaskValidationProvider } from 'src/state/TaskValidationProvider'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { TaskMediaProvider } from 'src/state/TaskAudioContext'
import { PanelTab, PanelTabsProvider } from 'src/components/Session/PanelTabsManager'

import { GlossaryProvider } from './GlossaryProvider'
import { ChatProvider } from './ChatProvider'
import { SessionMediaProvider } from './SessionMediaProvider'
import Logrocket from 'logrocket'

const env = getEnv()

interface SessionContextValue {
    taskId?: string
    taskType?: string
    taskLayerId?: LiveTranscriptionLayerId
    tagsModificationDate?: Date | null
    speakersModificationDate?: Date | null
    controls?: EditorControls
}

const SessionContext = createContext<SessionContextValue>({})

interface SessionProviderProps {
    taskId?: string
    taskType?: string
    taskLayerId?: LiveTranscriptionLayerId
    tagsModificationDate?: Date | null
    speakersModificationDate?: Date | null
    controls: EditorControls
    children: ReactNode
    initialPanelTab?: PanelTab | null
}

export const SessionProvider = ({
    children,
    taskId,
    taskType,
    tagsModificationDate,
    taskLayerId,
    speakersModificationDate,
    controls,
    initialPanelTab,
}: SessionProviderProps) => {
    const { sessionStatus: status } = useSessionStatus([
        'sessionStatus.sessionId',
        'sessionStatus.platformJobId',
        'sessionStatus.customerId',
        'sessionStatus.isTraining',
        'sessionStatus.vertical',
    ])
    const analytics = useAnalytics()
    const [{ context: appContext }] = useAppMachine([
        'workerId',
        'workerName',
        'workerEmail',
        'layerIds',
        'httpClient',
    ])
    const { workerId, workerName, workerEmail } = appContext
    const strategyName = appContext?.strategyName

    useEffect(() => {
        let logrocketInterval: ReturnType<typeof setInterval>

        if (!(status?.sessionId && workerId && workerEmail && workerName)) {
            return
        }

        if (status?.platformJobId) {
            analytics?.setPlatJobId(status.platformJobId)
        }

        if (status?.customerId) {
            analytics?.setCustomerId(status.customerId)
        }

        if (appContext?.mediaSource) {
            analytics?.setMediaSource(appContext.mediaSource)
        }

        analytics?.setIsTraining(status?.isTraining)
        analytics?.setSessionId(status.sessionId)
        analytics?.setStrategy(strategyName)
        analytics?.sendJoinSession(appContext?.featureFlags)
        analytics?.setVertical(status?.vertical)

        if (env !== 'dev') {
            const identifyLogrocket = () => {
                LogRocket.identify(workerId, {
                    email: workerEmail,
                    name: workerName,
                    env,
                })
            }
            logrocketInterval = setInterval(() => {
                if (!Logrocket.sessionURL) {
                    LogRocket.startNewSession()
                    identifyLogrocket()
                }
            }, 20000)
            identifyLogrocket()
        }

        return () => clearInterval(logrocketInterval)
    }, [
        analytics,
        workerId,
        workerName,
        workerEmail,
        strategyName,
        status?.sessionId,
        status?.vertical,
        status?.platformJobId,
        status?.isTraining,
        status?.customerId,
        appContext?.featureFlags,
        appContext?.mediaSource,
    ])

    return (
        <SessionContext.Provider
            value={{
                taskId,
                taskType,
                taskLayerId,
                tagsModificationDate,
                speakersModificationDate,
                controls,
            }}
        >
            <SessionMediaProvider>
                <PanelTabsProvider initialTab={initialPanelTab}>
                    <SpeakersProvider>
                        <ExhibitsProvider>
                            <EventsMarkingProvider>
                                <GlossaryProvider>
                                    <ChatProvider>
                                        <LegalAnnotationsProvider>
                                            <TaskMediaProvider>
                                                <SpeakerVoiceSamplePlayerProvider>
                                                    <TaskValidationProvider>
                                                        {children}
                                                    </TaskValidationProvider>
                                                </SpeakerVoiceSamplePlayerProvider>
                                            </TaskMediaProvider>
                                        </LegalAnnotationsProvider>
                                    </ChatProvider>
                                </GlossaryProvider>
                            </EventsMarkingProvider>
                        </ExhibitsProvider>
                    </SpeakersProvider>
                </PanelTabsProvider>
            </SessionMediaProvider>
        </SessionContext.Provider>
    )
}

export const useSession = () => {
    const context = useContext(SessionContext)

    if (!context) {
        throw new Error('You have forgot to use SessionContext, shame on you.')
    }

    return context
}
